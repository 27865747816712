<template>
  <div class="add-funds-to-reserve-overview">
    <div class="add-funds-to-reserve-title">
      <div class="add-funds-to-reserve-header">
        {{ $t('addFundsToReserve.tabs.enterAmount') }}
      </div>
      <div class="add-funds-to-reserve-sub-header">
        {{ $t('addFundsToReserve.desc.enterAmount') }}
      </div>
    </div>
    <div class="divider"></div>
    <FundsReserveDetailsCard
      :fund-reserve-name="$t('addFundsToReserve.reserves.refundReserve')"
      :incoming-funds-to-reserve="getPendingAmountToRefundReserve"
      :remaining-funds-in-reserve="getCurrentAmountInRefundReserve"
      :info-for-funds-reserve="$t('addFundsToReserve.reserves.refundReserveInfo')"
      :entered-amount-from-store="getAmountAddedForRefundReserveInDollars"
      :hint="hintForRefundReserve"
      @enteredAmount="(value) => setAmountAddedForRefundReserve(value)"
      :error="getErrorForRefundReserve"
    />
    <FundsReserveDetailsCard
      :fund-reserve-name="$t('addFundsToReserve.reserves.chargebackReserve')"
      :incoming-funds-to-reserve="getPendingAmountToChargebackReserve"
      :remaining-funds-in-reserve="getCurrentAmountInChargebackReserve"
      :info-for-funds-reserve="$t('addFundsToReserve.reserves.chargebackReserveInfo')"
      :entered-amount-from-store="getAmountAddedForChargebackReserveInDollars"
      :hint="hintForChargebackReserve"
      @enteredAmount="(value) => setAmountAddedForChargebackReserve(value)"
      :error="getErrorForChargebackReserve"
    />
    <c-inline-error
      class="error-container"
      v-if="Boolean(getEnterAmountError)"
      :message="getEnterAmountError"
    />
    <div class="switch-to-next">
      <c-button variant="primary" @click="next" :loading="loading" :disabled="loading">
        {{ $t('global.action.next') }}
      </c-button>
    </div>
  </div>
</template>


<script>
import FundsReserveDetailsCard from "@/components/deposits/FundsReserveDetailsCard.vue";
import {mapActions, mapGetters} from "vuex";
import Formatter from "@/app/utils/common/functions/formatter";

export default {
  name: "AddFundsToReserve",
  components: {
    FundsReserveDetailsCard,
  },
  props: {
    next: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data: function() {
    return {
      hintForRefundReserve: null,
      hintForChargebackReserve: null,
    }
  },
  computed: {
    ...mapGetters('merchantBalanceReserve', [
        'getCurrentAmountInRefundReserve',
        'getCurrentAmountInChargebackReserve',
        'getPendingAmountToRefundReserve',
        'getPendingAmountToChargebackReserve',
        'getAmountAddedForChargebackReserve',
        'getAmountAddedForRefundReserve',
        'getErrorForRefundReserve',
        'getErrorForChargebackReserve',
        'getEnterAmountError'
    ]),
    ...mapGetters('app', ['getMerchantCurrency']),
    getAmountAddedForRefundReserveInDollars() {
      return this.getAmountAddedForRefundReserve / 100.0;
    },
    getAmountAddedForChargebackReserveInDollars() {
      return this.getAmountAddedForChargebackReserve / 100.0;
    }
  },
  mounted() {
    this.initialise();
  },
  methods: {
    ...mapActions('merchantBalanceReserve', [
        'setAmountAddedForRefundReserve',
        'setAmountAddedForChargebackReserve',
    ]),
    initialise() {
      if (this.getCurrentAmountInRefundReserve !== undefined && this.getCurrentAmountInRefundReserve !== null
          && this.getCurrentAmountInRefundReserve < 0) {
        this.hintForRefundReserve = this.$t('addFundsToReserve.message.addAtLeast')
            + this.convertCentToDollar(this.getCurrentAmountInRefundReserve);
      }
      if (this.getCurrentAmountInChargebackReserve !== undefined && this.getCurrentAmountInChargebackReserve !== null
          && this.getCurrentAmountInChargebackReserve < 0) {
        this.hintForChargebackReserve = this.$t('addFundsToReserve.message.addAtLeast')
            + this.convertCentToDollar(this.getCurrentAmountInChargebackReserve);
      }
    },
    convertCentToDollar(number) {
      const absNumDollar = Math.abs(Number(number));
      return Formatter.format({
        type: "currency",
        data: absNumDollar,
        currencyCode: this.getMerchantCurrency
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-funds-to-reserve-overview {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;

  & .add-funds-to-reserve-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    & .add-funds-to-reserve-header {
      font-size: 24px;
      font-weight: $weight_bold;
    }

    & .add-funds-to-reserve-sub-header {
      font-size: 12px;
      font-weight: $weight_normal;
    }
  }

  & .divider {
    width: 100%;
    background: $neutral_200;
    height: 1px;
    margin-top: 20px;
    margin-bottom: 8px;
  }

  & .error-container {
    margin-top: 20px;
  }

  & .switch-to-next {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 60px;
    max-width: 120px;

    & .c-button--primary {
      margin-bottom: 0;
      width: 140px;
    }
  }
}
</style>

