<template>
  <div class="breakdown">
    <h2 class="show-in-pdf-only" :style="{padding: '20px'}">
      {{ 'Deposit breakdown: #' + getMerchantSettlementDeposit(settlement).id }}
    </h2>
    <table aria-describedby="Show settlement breakdown">
      <tr class="table-header">
        <th>
          {{ $t(`deposits.breakdown.header.component`) }}
        </th>
        <th class="number">
          {{ $t(`deposits.breakdown.header.number`) }}
        </th>
        <th class="number">
          {{ $t(`deposits.breakdown.header.amount`) }}
        </th>
      </tr>
      <tr v-if="settlement.breakdown.paymentsAmount !== null">
        <td>
          {{ $t(`deposits.breakdown.payments`) }}
        </td>
        <td class="number">
          {{ settlement.breakdown.numberOfPayments }}
        </td>
        <td class="number">
          {{ formatMoney(settlement.breakdown.paymentsAmount) }}
        </td>
      </tr>
      <tr v-if="settlement.total_fee !== null">
        <td>
          {{ $t(`deposits.breakdown.paymentsProcessingFee`) }}
        </td>
        <td class="number">
        </td>
        <td class="number">
          {{ formatMoney(settlement.total_fee * -1) }}
        </td>
      </tr>
      <tr v-if="settlement.breakdown.chargebackCreditsAmount != null">
        <td>
          {{ $t(`deposits.breakdown.credits.fieldName`) }}
          <ToolTip
            placement="top-start"
            :arrow="false"
            :content="$t(`deposits.breakdown.credits.tooltip`)"
          >
            <img alt="dropdown" class="statistics-info-icon" src="@/app/assets/images/info-outline.svg" />
          </ToolTip>
        </td>
        <td class="number">
          {{ settlement.breakdown.numberOfChargebackCredits }}
        </td>
        <td class="number">
          {{ formatMoney(settlement.breakdown.chargebackCreditsAmount) }}
        </td>
      </tr>
      <tr v-if="settlement.breakdown.refundsAmount != null">
        <td>
          {{ $t(`deposits.breakdown.refunds`) }}
        </td>
        <td class="number">
          {{ settlement.breakdown.numberOfRefunds }}
        </td>
        <td class="number">
          {{ formatMoney(settlement.breakdown.refundsAmount * -1) }}
        </td>
      </tr>
      <tr v-if="settlement.breakdown.chargebackDebitsAmount != null">
        <td>
          {{ $t(`deposits.breakdown.chargebacks`) }}
        </td>
        <td class="number">
          {{ settlement.breakdown.numberOfChargebackDebits }}
        </td>
        <td class="number">
          {{ formatMoney(settlement.breakdown.chargebackDebitsAmount * -1) }}
        </td>
      </tr>
      <tr v-if="settlement.breakdown.chargebackFeesAmount != null">
        <td>
          {{ $t(`deposits.breakdown.chargebackFees`) }}
        </td>
        <td class="number">
        </td>
        <td class="number">
          {{ formatMoney(settlement.breakdown.chargebackFeesAmount * -1) }}
        </td>
      </tr>
    </table>
    <div class="total">
      <div class="total-title">
        {{ $t(`deposits.breakdown.depositAmount`) }}
      </div>
      <div class="total-amount">
        {{ getDepositAmount() }}
      </div>
    </div>
  </div>
</template>
<script>
import Formatter from "@/app/utils/common/functions/formatter";
import {DepositSubType, SettlementWithBreakdownDto} from "@/api/paymentApi";
import ToolTip from "@/components/common/ToolTip.vue";

export default {
  name: "ExportableSettlementBreakdown",
  components: {ToolTip},
  props: {
    settlement: {
      type: SettlementWithBreakdownDto,
      required: true,
    },
  },
  methods: {
    formatDateWithTimezone(date) {
      return Formatter.format({type: 'zonedDateWithTime', data: date})
    },
    format(type, data, format, currencyCode) {
      return Formatter.format({type, data, format, currencyCode})
    },
    formatMoney(amount) {
      return Formatter.format({
        type: 'currency',
        data: amount,
        currencyCode: this.settlement.currency,
      }) + " " + this.settlement.currency
    },
    getMerchantSettlementDeposit(settlement) {
      return settlement.deposits.find(d => d.subType === DepositSubType.MERCHANT) || {}
    },
    getMerchantDepositAmount() {
      return this.getMerchantSettlementDeposit(this.settlement).type === 'DEBIT'
          ? this.getMerchantSettlementDeposit(this.settlement).amount * (-1)
          : this.getMerchantSettlementDeposit(this.settlement).amount;
    },
    getDepositAmount() {
      if (this.getMerchantDepositAmount() === undefined) {
        return null;
      }
      return Formatter.format({
        type: 'currency',
        data: this.getMerchantDepositAmount(),
        currencyCode: this.settlement.currency,
      }) + " " + this.settlement.currency
    },
  }
}
</script>
<style lang="scss">
.breakdown {

  & table {
    border: 1px solid #EEF1F7;
    border-radius: 8px;
    border-collapse: inherit;
    width: 100%;
    height: 100%;
    text-align: left;
    border-spacing: 0;

    & th {
      font-weight: $weight_normal;
      background: #EEF1F7;
    }

    & th:nth-child(1) {
      border-radius: 8px 0px 0px 0px;
    }

    & th:nth-child(3) {
      border-radius: 0px 8px 0px 0px;
    }

    & td, th {
      padding: 14px 24px;
      border-bottom: 1px solid #EEF1F7;
    }

    & tr:last-child {
      & td {
        border-bottom: none;
      }
    }

    & .number {
      text-align: right;
    }
  }

  & .total {
    width: 350px;
    margin-top: 24px;
    margin-right: 24px;
    height: 30px;
    display: flex;
    float: right;
    justify-content: space-between;
    align-items: center;
    font-weight: $weight_bold;
    font-size: 14px;

    & .total-amount {
      font-size: 16px;
    }
  }
}
</style>