<template>
  <div class="main-container">
    <SidebarNav @update:sidebarNavCollapsed="sidebarNavCollapsed = $event" />
    <SearchBar :sidebar-nav-collapsed="sidebarNavCollapsed" />
    <div
      class="profile-container"
      :class="{ ['profile-container-sidebar-collapse']: sidebarNavCollapsed }"
    >
      <div class="profile-sub-container">
        <div class="profile-page-header">
          {{ $t('profile.title') }}
        </div>

        <div class="nav">
          <div
            class="nav-item"
            v-for="tab in tabs"
            :key="tab.name"
            :class="{ 'nav-item-active': tab.name === currentTab, [tab.name]: true, }"
            @click="() => switchTab(tab.name)"
          >
            <span>{{ $t('profile.tabs.' + tab.name) }}</span>
          </div>
        </div>
        <OnboardingView v-if="currentTab === 'PAYMENTS_PROFILE'" />
        <ComplianceFormList v-if="currentTab === 'COMPLIANCE'" />
        <PortalFooter />
      </div>
    </div>
  </div>
</template>

<script>
import SidebarNav from '@/components/SidebarNav';
import OnboardingView from '@/views/OnboardingView';
import SearchBar from '@/components/SearchBar.vue';
import PortalFooter from '@/components/portal/PortalFooter.vue';
import ComplianceFormList from '@/components/complianceforms/ComplianceFormList.vue';
import {portalCheckMixin} from "@/mixin/portalPageCheck";

export default {
  name: 'ProfileView',
  components: {
    ComplianceFormList,
    SearchBar,
    SidebarNav,
    PortalFooter,
    OnboardingView,
  },
  mixins: [portalCheckMixin],
  beforeRouteEnter(to, from, next) {
    if (to.name === 'profile' && from.name !== 'compliance_form_details') {
      window.cbStorage.setItem('cb_profile_current_tab', 'PAYMENTS_PROFILE');
    }
    next();
  },
  data: () => {
    return {
      sidebarNavCollapsed: false,
      currentTab: 'PAYMENTS_PROFILE',
      tabs: [
        {
          name: 'PAYMENTS_PROFILE',
        },
        {
          name: 'COMPLIANCE',
        },
      ],
    };
  },
  mounted() {
    this.portalChecks().then(() => {
      if (window.cbStorage.getItem('cb_profile_current_tab') !== null) {
        this.currentTab = window.cbStorage.getItem('cb_profile_current_tab');
      }
    });
  },
  methods: {
    switchTab: function (nextTab) {
      if (this.currentTab === nextTab) {
        return;
      }
      this.currentTab = nextTab;
      window.cbStorage.setItem('cb_profile_current_tab', this.currentTab);
    },
  },
};
</script>

<style lang="scss">
.main-container {
  background: $neutral_50;
  height: 100%;
  overflow: scroll;
}

.profile-container {
  left: 256px;
  top: 0;
  position: relative;
  width: calc(100% - 256px);
  padding: 20px 30px 30px 30px;
  transition: all 0.2s ease-in;
  justify-content: center;
  display: flex;

  &.profile-container-sidebar-collapse {
    left: 86px;
    width: calc(100% - 86px);
  }
  & .profile-sub-container {
    width: 100%;
    max-width: 1360px;

    & .profile-page-header {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: $neutral_1000;
      text-align: left;
      padding: 20px 0;
    }

    & .nav {
      display: flex;
      flex-direction: row;
      font-size: 14px;
      font-weight: $weight_medium;
      background-color: transparent;
      padding-left: 0;
      border-bottom: 1px solid $neutral_200;
      margin-bottom: 24px;

      &:after {
        content: '';
        height: 2px;
        background-color: $color_white;
      }

      & .nav-item {
        display: flex;
        cursor: pointer;
        align-items: start;
        margin-right: 16px;
        color: $primary_text_dark;

        @media screen and (min-width: 801px) {
          padding: 12px 16px;
        }

        @media screen and (max-width: 800px) {
          padding: 10px 16px;
        }

        &:hover {
          background-color: $primary_fill_light;
        }

        &:disabled:hover {
          background-color: $color_white;
          cursor: not-allowed;
        }
      }

      & .nav-item-active {
        color: $primary_text;
        border-bottom: 2px solid $primary_400;

        & svg {
          margin-right: 10px;
          color: $primary_text;
        }
      }
    }
  }
}
</style>