<template>
  <div class="main-container">
    <SidebarNav @update:sidebarNavCollapsed="sidebarNavCollapsed = $event" />
    <SearchBar :sidebar-nav-collapsed="sidebarNavCollapsed" />
    <div
      class="reports-container"
      :class="{['reports-container-sidebar-collapse']: sidebarNavCollapsed}"
    >
      <div class="reports-sub-container">
        <div class="table-header">
          {{ $t('reports.title') }}
        </div>

        <div class="table-header-description">
          {{ $t('reports.description') }}
        </div>

        <ReportTableFilterOption
          :disabled="loading.all"
          table-label="Reports"
          v-bind="tableFilterOptionConfig"
          @childApplyFilter="applyFilter"
          @childClearFilter="applyFilter"
          ref="tableFilterOptionComponent"
          v-if="!noReportsYet"
        />
        <div class="summary-container" v-if="!loading.all">
          <div class="summary-header" v-if="!noReportsYet && reportExists && renderSummary">
            <div class="summary-title">
              {{ $t('reports.heading', {month: getSummaryMonth, year: getSummaryYear}) }}
            </div>
            <c-button
              variant="primary-outline"
              :disabled="loading.all"
              :loading="loading.report"
              @click="downloadReport"
              class="primary-outline-btn"
            >
              <DownloadsIcon />
              {{ $t('reports.download') }}
            </c-button>
          </div>

          <div class="summary-table" v-if="!noReportsYet && reportExists && renderSummary">
            <c-table :variant="'stroke'">
              <c-tr v-for="summaryMetric in reportSummaryMetrics" :key="summaryMetric[0]">
                <c-th class="title-cell">
                  {{ $t('reports.summary.' + summaryMetric[0]) }}
                  <ToolTip
                    placement="right"
                    v-if="summaryMetric[0] === 'deposits'"
                    :content="$t('reports.summary.tooltip.deposits')"
                  >
                    <img alt="dropdown" class="statistics-info-icon" src="@/app/assets/images/info-outline.svg" />
                  </ToolTip>
                </c-th>
                <c-th class="value-cell">
                  {{ formatAmount(summaryMetric[1], summaryMetric[0]) }} {{ getMerchantCurrency }}
                </c-th>
              </c-tr>
            </c-table>
          </div>
          <div class="empty-container" v-if="!reportExists || noReportsYet">
            <EmptyPlaceholder
              :heading-tag="noReportsYet ? $t('reports.noReportsYetTitle') : $t('reports.reportNotFoundTitle')"
              :description-tag="noReportsYet ? $t('reports.noReportsYetDesc') : $t('reports.reportNotFoundDesc')"
              :clear-filter-flag="false"
            >
              <div slot="icon">
                <FolderIcon v-if="noReportsYet" class="empty-placeholder-icon" />
                <NoResultsIcon v-else class="empty-placeholder-icon" />
              </div>
            </EmptyPlaceholder>
          </div>
        </div>
        <div class="loading-message" v-else>
          <div class="summary-table">
            <c-table variant="stroke">
              <c-tr v-for="index in 6" :key="index">
                <c-th>
                  <div class="line loading-shimmer"></div>
                </c-th>
                <c-th>
                  <div class="value-shimmer">
                    <div class="line loading-shimmer"></div>
                  </div>
                </c-th>
              </c-tr>
            </c-table>
          </div>
        </div>
        <PortalFooter />
      </div>
    </div>
  </div>
</template>

<script>
import ExcelJS from 'exceljs';
import axios from 'axios';
import _ from 'lodash';
import SidebarNav from "@/components/SidebarNav.vue";
import SearchBar from "@/components/SearchBar.vue";
import ReportTableFilterOption from "@/components/common/ReportTableFilterOption";
import reports from "@/api/reports";
import {ReportType} from "@/api/paymentApi";
import {mapGetters} from "vuex";
import {portalCheckMixin} from "@/mixin/portalPageCheck";
import Formatter from "@/app/utils/common/functions/formatter";
import PortalFooter from "@/components/portal/PortalFooter";
import EmptyPlaceholder from "@/components/transactions/EmptyPlaceholder.vue";
import FolderIcon from "@/components/icons/FolderIcon";
import DownloadsIcon from "@/components/icons/DownloadsIcon.vue";
import NoResultsIcon from "@/components/icons/NoResultsIcon.vue";
import {
  getDateTimeFromJSDate,
  getDateTimeNow,
  isGivenDateBetweenFirstAndFifth
} from "@/app/utils/common/functions/dateUtils";
import ToolTip from "@/components/common/ToolTip.vue";

const CSV_DELIMITER_FOR_REPORTS = ';';
const FEE = "FEE";

export default {
  name: 'ReportView',
  components: {
    ToolTip,
    NoResultsIcon,
    DownloadsIcon,
    SearchBar, SidebarNav, ReportTableFilterOption, PortalFooter, EmptyPlaceholder, FolderIcon
  },
  mixins: [portalCheckMixin],
  data() {
    return {
      report: null,
      reportExists: true,
      reportType: ReportType.CONSOLIDATED,
      sidebarNavCollapsed: false,
      summaryDetails: null,
      payoutDetails: null,
      disputeDetails: null,
      transactionDetails: null,
      loading: {
        all: false,
        report: false,
      },
      startDate: null,
      endDate: null,
      renderSummary: false,
      tableFilterOptionConfig: {},
    };
  },
  computed: {
    ...mapGetters('app', ['getMerchantId', 'getMerchantCreatedAt', 'isAdminMode', 'getMerchantCurrency']),
    reportSummary() {
      return JSON.parse(this.report?.summary || null);
    },
    reportSummaryMetrics() {
      const summaryMetrics = ["GROSS_VOLUME", FEE, "NET_VOLUME", "REFUNDS", "CHARGEBACKS", "DEPOSITS"];
      const orderedEntries = summaryMetrics.map(key => [this.getReportSummaryKey(key), this.reportSummary[key]]);
      return new Map(orderedEntries);
    },
    noReportsYet() {
      const merchantCreatedAt = new Date(this.getMerchantCreatedAt);
      const currentDate = new Date();
      const merchantCreatedAtYear = merchantCreatedAt.getFullYear();
      const merchantCreatedAtMonth = merchantCreatedAt.getMonth();

      const dateYear = currentDate.getFullYear();
      const dateMonth = currentDate.getMonth();

      /* Monthly reports are not available for the merchant created at month( say Mo) and upto fifth of the next
      * month (Mo+1). On the 5th day of (Mo+1) month at 08:30 AM UTC, reports are generated. So
      * isNextMonthDateBetweenFirstAndFifth(Mo) check is added to ensure no reports request are made until available.*/
      return (merchantCreatedAtYear === dateYear && merchantCreatedAtMonth === dateMonth)
          || this.isNextMonthDateBetweenFirstAndFifth(merchantCreatedAtMonth);
    },
    getSummaryMonth() {
      return getDateTimeFromJSDate(this.startDate)?.monthLong;
    },
    getSummaryYear() {
      return getDateTimeFromJSDate(this.startDate)?.year;
    },
  },
  mounted() {
    this.portalChecks().then(() => {
      this.initialize();
    });
  },
  methods: {
    initialize() {
      this.initializeDates();
      if (!this.noReportsYet) {
        this.fetchSummary();
      }
    },
    async fetchSummary() {
      this.loading.all = true;
      try {
        this.report = await reports.fetchReport(
            this.getMerchantId,
            this.reportType,
            this.startDate,
            this.endDate,
        );
        this.reportExists = true;
        this.renderSummary = true;
      } catch (e) {
        this.$danger(this.$t('reports.reportNotFoundError', {month: this.startDate
              ?.toLocaleString('default', {month: 'long'}), reportType: this.reportType}),
          {dismissable: true, timeout: 4000});
        this.reportExists = false;
      } finally {
        this.loading.all = false;
      }
    },
    initializeDates() {
      this.startDate = this.getDefaultStartDate().toJSDate();
      this.endDate = this.getDefaultEndDate().toJSDate();
    },
    resetReportDetails() {
      this.summaryDetails = this.payoutDetails = this.transactionDetails = this.disputeDetails = null;
    },
    isNextMonthDateBetweenFirstAndFifth(merchantCreatedAtMonth) {
      const currentDate = getDateTimeNow();
      return ((merchantCreatedAtMonth + 2) === currentDate.month) && isGivenDateBetweenFirstAndFifth(currentDate);
    },
    getDefaultStartDate() {
      const currentDate = getDateTimeNow();
      const monthsToSubtract = isGivenDateBetweenFirstAndFifth(currentDate) ? 2 : 1;
      return currentDate.minus({ months: monthsToSubtract }).startOf('month');
    },
    getDefaultEndDate() {
      const currentDate = getDateTimeNow();
      const monthsToSubtract = isGivenDateBetweenFirstAndFifth(currentDate) ? 1 : 0;
      return currentDate.minus({ months: monthsToSubtract }).startOf('month');
    },
    getReportSummaryKey(key) {
      return key.toLowerCase();
    },
    applyFilter(filter) {
      let startDateTime = this.getStartDateTime(filter.currentMonthFilter);
      this.startDate = startDateTime.toJSDate();
      this.endDate = this.getNextMonthStartDate(startDateTime).toJSDate();
      this.fetchSummary();
    },
    getStartDateTime(monthYear) {
      const [month, year] = monthYear.split("-");
      const monthIndex = new Date(`01 ${month}, ${year}`).getMonth() + 1;
      const startDate = this.getDefaultStartDate();
      return startDate.set({ month: monthIndex, year: year });
    },
    getNextMonthStartDate(givenDate) {
      return givenDate.plus({months: 1});
    },
    formatAmount(amount, summaryMetric) {
      if (summaryMetric === 'fee')
        amount = amount * -1;
      return Formatter.format({ type: "currency", data: amount, currencyCode: this.getMerchantCurrency });
    },
    async downloadReport() {
      try {
        this.loading.report = true;
        const downloadedReportDetails = await reports.downloadReport(this.getMerchantId, this.report.id);
        await this.downloadReportDetails(downloadedReportDetails);
        this.$success(this.$t('reports.successToast'));
      } catch (e) {
        this.$danger("An error occurred while downloading the report.");
      } finally {
        this.loading.report = false;
      }
    },
    async downloadReportDetails(downloadedReportDetails) {
      this.resetReportDetails();
      if (downloadedReportDetails.summary_url) {
        const summaryResponse = await this.getReportDataFromUrl(downloadedReportDetails.summary_url);
        this.summaryDetails = this.parseCsvData(summaryResponse);
      }
      if (downloadedReportDetails.payouts_url) {
        const payoutResponse = await this.getReportDataFromUrl(downloadedReportDetails.payouts_url);
        this.payoutDetails = this.parseCsvData(payoutResponse);
      }
      if (downloadedReportDetails.transactions_url) {
        const transactionsResponse = await this.getReportDataFromUrl(downloadedReportDetails.transactions_url);
        this.transactionDetails = this.parseCsvData(transactionsResponse);
      }
      if (downloadedReportDetails.disputes_url) {
        const disputesResponse = await this.getReportDataFromUrl(downloadedReportDetails.disputes_url);
        this.disputeDetails = this.parseCsvData(disputesResponse);
      }
      await this.processExcelFile();
    },
    async getReportDataFromUrl(url) {
      const response = await axios.get(url, { responseType: 'text' });
      return response.data;
    },
    parseCsvData(csvData) {
      const csvEndOfFileRegex = /\r?\n$/i;
      const cleanedCsvData = csvData.replace(csvEndOfFileRegex, '');
      const lines = cleanedCsvData.split(/\r?\n/);
      return lines.map(entry => entry.split(CSV_DELIMITER_FOR_REPORTS));
    },
    async processExcelFile() {
      const workbook = await this.loadWorkbookFromTemplate();
      if (this.summaryDetails) {
        this.writeDataToNamedRange(workbook, 'ConsolidatedSummary', this.summaryDetails);
      }
      if (this.transactionDetails) {
        this.writeDataToNamedRange(workbook, 'TransactionsReport', this.transactionDetails);
      }
      if (this.payoutDetails) {
        this.writeDataToNamedRange(workbook, 'PayoutsReport', this.payoutDetails);
      }
      if (this.disputeDetails) {
        this.writeDataToNamedRange(workbook, 'DisputesReport', this.disputeDetails);
      }
      this.updateHeadingTitles(workbook);
      await this.saveWorkbookAsFile(workbook, 'consolidated_report.xlsx');
    },
    async loadWorkbookFromTemplate() {
      const response = await axios.get("/consolidated_report_template.xlsx", { responseType: 'blob' });
      const buffer = await response.data.arrayBuffer();
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(buffer);
      return workbook;
    },
    writeDataToNamedRange(workbook, namedRange, arrayData) {
      const worksheet = workbook.getWorksheet(1);
      const namedRangeObject = this.findNamedRange(workbook, namedRange, 'Sheet1');
      if (!namedRangeObject) {
        throw new Error(`Named range '${namedRange}' not found.`);
      }
      const startKey = _.findKey(namedRangeObject[0]);
      const endKey = _.findLastKey(namedRangeObject[0]);
      let start = namedRangeObject[0][startKey];
      let end = namedRangeObject[0][endKey];
      const startRow = start.row;
      const startColumn = start.col;
      const endColumn = end.col;
      const numberOfColumns = endColumn - startColumn + 1;

      for (let rowIndex = 1, row = startRow; rowIndex < arrayData.length; rowIndex++, row++) {
        for (let colIndex = 0, col = startColumn; colIndex < numberOfColumns; colIndex++, col++) {
          let cell = worksheet.getCell(row, col);
          let cellValue = arrayData[rowIndex][colIndex];
          const dataType = arrayData[0][colIndex];
          const cellStyle = {
            ...cell.style,
            border: {
              ...cell.style.border,
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            },
            alignment: {
              ...cell.style.alignment,
              vertical: 'middle',
              horizontal: 'center'
            }
          };
          cell.value= Formatter.formatReportDataForExcel(cellValue, dataType);
          const excelDataFormat = Formatter.setNumberFormattingForExcel(dataType, this.getMerchantCurrency)
          if (excelDataFormat !== null) {
            cellStyle.numFmt = excelDataFormat;
          }
          cell.style = cellStyle;
        }
      }
    },
    findNamedRange(workbook, namedRange, sheetName) {
      const namedRanges = workbook.definedNames.matrixMap[namedRange].sheets[sheetName];
      return _.compact(namedRanges);
    },
    updateHeadingTitles(workbook) {
      const month = this.getSummaryMonth;
      const year = this.getSummaryYear;
      const worksheet = workbook.getWorksheet(1);
      const summaryTitleCell = worksheet.getCell('2', '2');
      summaryTitleCell.value = `${this.$t('reports.heading', { month, year })}`;
      const txnTitleCell = worksheet.getCell('2', '6');
      txnTitleCell.value = `Transactions Report ${month} ${year}`;
      const payoutTitleCell = worksheet.getCell('2', '15');
      payoutTitleCell.value = `Payouts Report ${month} ${year}`;
      const disputeTitleCell = worksheet.getCell('2', '24');
      disputeTitleCell.value = `Disputes Report ${month} ${year}`;
    },
    async saveWorkbookAsFile(workbook, fileName) {
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = fileName;
      downloadLink.click();
    },
  },
};
</script>

<style lang="scss">

.main-container {
  background: #f4f5f7;
  height: 100%;
  overflow: scroll;
}

.reports-container {
  left: 256px;
  top: 0;
  position: relative;
  width: calc(100% - 256px);
  padding: 20px 30px 30px 30px;
  transition: all 0.2s ease-in;
  justify-content: center;
  display: flex;

  & .reports-sub-container {
    width: 100%;
    max-width: 1360px;
  }

  &.reports-container-sidebar-collapse {
    left: 86px;
    width: calc(100% - 86px);
  }

  .empty-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    height: 375px;
  }

  & .empty-placeholder-icon {
    display: flex;
    width: 40px;
    height: 40px;
  }

  & .table-header {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: $neutral_1000;
    text-align: left;
    padding: 20px 0 0 0;
  }

  & .table-header-description {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $neutral_600;
    text-align: left;
    padding: 8px 0 24px 0;
  }

  .summary-container {
    background-color: $color_white;
    width: 100%;
    height: fit-content;
    border: 1px solid $neutral_100;
    border-radius: 6px 6px 6px 6px;
    flex-direction: row;
    justify-content: right;
    padding: 20px 24px;

    & .summary-header {
      text-align: left;
      font-size: 16px;
      font-weight: $weight-bold;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & .summary-title {
        font-style: normal;
        line-height: 30px;
      }

      & .c-button {
        width: fit-content;
        height: 32px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        background-color: #e5f3fa;
        border: 1px solid #8ccce6;
        color: #2d6a8a;
        padding: 7px 12px 7px 12px;
        margin: 0px;

        & svg{
          margin-right: 5px;
        }
      }

      & .c-button__label {
        padding: 0;
      }

      & .c-button:hover {
        color: #2d6a8a;
        background-color: #b5def0;
      }
    }
  }

  & .loading-message {

    & .c-table {
      background: #FFFFFF;
      height: 375px;
    }
    & .line {
      height: 15px;
      margin: 10px 0;
      width: 157px;
    }

    & .value-shimmer {
      display: flex;
      flex-direction: row-reverse;
      margin-right: 10px;
    }

    & .loading-shimmer {
      animation: shimmer 2s infinite linear;
      background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
      background-size: 1000px 100%;
      border-radius: 10px;
    }
  }

  & .summary-table {
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid $neutral_100;

    & .c-table {
      & .c-table__tr {
        border-bottom: 1px solid $neutral_100;
      }
    }
  }

  & .title-cell {
    display: flex;
    align-items: center;
    align-self: stretch;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 16px 24px;

    & .c-tooltip__trigger {
      margin-left: 4px;
    }
  }

  & .statistics-info-icon {
    vertical-align: text-bottom;
    width: 14px;
    height: 14px;
  }

  & .value-cell {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 16px 24px;
    text-align: right;
  }

}

</style>
