<template>
  <div class="main-container">
    <SidebarNav @update:sidebarNavCollapsed="sidebarNavCollapsed = $event" />
    <SearchBar :sidebar-nav-collapsed="sidebarNavCollapsed" />
    <div
      class="authorizations-container"
      :class="{['authorizations-container-sidebar-collapse']: sidebarNavCollapsed}"
    >
      <div class="authorizations-sub-container">
        <div class="table-header">
          {{ $t('authorizations.title') }}
        </div>

        <div class="table-header-description">
          {{ $t('authorizations.description') }}
        </div>

        <div class="nav">
          <div
            class="nav-item"
            v-for="tab in tabs"
            :key="tab.name"
            :class="{'nav-item-active': tab.name === currentTab, [tab.name]: true}"
            @click="() => switchTab(tab.name)"
            :id="getElementId('tab-' + tab.name)"
          >
            <span>{{ $t('authorizations.tabs.' + tab.name) }}</span>
          </div>
        </div>

        <TableFilterOption
          :disabled="loading.all"
          table-label="Authorizations"
          :current-tab="currentTab"
          :date-filter-duration="12"
          v-bind="tableFilterOptionConfig"
          :enable-filters="['dateFilter', 'statusFilter']"
          ref="tableFilterOptionComponent"
          @childApplyFilter="applyFilter"
          @childClearFilter="applyFilter"
        />

        <Search
          :placeholder="$t('authorizations.search')"
          @search="searchAuthorizations"
          :disabled="authorizations === null || authorizations.length === 0"
          class="search-container"
        />

        <div class="header-container">
          <div class="authorizations-header-count">
            <div v-if="count!==0" class="showing-count-top" :class="{'hide-loader': loading.all || authorizations.length===0}">
              {{ $t('authorizations.showing') }} <b class="pagination">{{ authorizationsCount }}</b>
            </div>
            <div v-else class="showing-count-top" :class="{'hide-loader': loading.all}">
              {{ $t('authorizations.noAuthorizationsFound') }}
            </div>
            <div class="loader" :class="{'hide-loader': !loading.all}"></div>
          </div>
          <export-button
            :disabled="loading.all || authorizations.length === 0 || searchQuery !== '' || isAdminMode"
            :loading="loading.export"
            :create-export="createExport"
            :id="getElementId('export')"
            :tooltip-enabled="!(searchQuery ==='' && authorizations.length>0) && !isAdminMode"
            :tooltip-content="tooltipContent()"
          />
        </div>

        <TableView
          :data="filteredAuthorizations"
          :loading="loading.all"
          :infinite-scroll="searchQuery === ''"
          class="authorization-table"
          :all-items-loaded="allItemsLoaded"
          :columns="getColumns"
          entity="authorizations"
          @row-clicked="rowClicked"
          :clickable-rows="true"
          :fixed-first-column="false"
          @clearFilter="clearFilterFromEmptyPlaceholder"
        >
          <template #authorization="prop">
            <div class="authorization-column">
              <ToolTip
                placement="top-start"
                :content="prop.item.error_reason"
                :no-wrap="true"
                :dismiss-on-click="true"
                v-if="prop.item && prop.item.error_reason"
              >
                <div class="authorization-column">
                  <div class="status-tag-container">
                    <div
                      class="status"
                      :class="{
                        'success-status': prop.item.status === 'SUCCEEDED',
                        'failure-status': prop.item.status === 'FAILED',
                        'canceled-status': ['CANCELED', 'UNAVAILABLE'].includes(prop.item.status),
                        'progress-status': prop.item.status === 'PENDING'
                      }"
                    >
                      {{ $t('authorizations.status.' + prop.item.status) }}
                    </div>
                  </div>
                  <div class="amount-and-txid">
                    <div class="amount_box">
                      <div class="amount">
                        {{ formatter({type : "currency", data: prop.item.amount, currencyCode: prop.item.currency}) }}
                      </div>
                      <div class="currency_code">
                        {{ prop.item.currency }}
                      </div>
                    </div>
                    <div class="authorization-id">
                      {{ prop.item.id }}
                    </div>
                  </div>
                </div>
              </ToolTip>
              <div v-else>
                <div class="authorization-column">
                  <div class="status-tag-container">
                    <div
                      class="status"
                      :class="{
                        'success-status': prop.item.status === 'SUCCEEDED',
                        'failure-status': prop.item.status === 'FAILED',
                        'canceled-status': ['CANCELED', 'UNAVAILABLE'].includes(prop.item.status),
                        'progress-status': prop.item.status === 'PENDING'
                      }"
                    >
                      {{ $t('authorizations.status.' + prop.item.status) }}
                    </div>
                  </div>
                  <div class="amount-and-txid">
                    <div class="amount_box">
                      <div class="amount">
                        {{ formatter({type : "currency", data: prop.item.amount, currencyCode: prop.item.currency}) }}
                      </div>
                      <div class="currency_code">
                        {{ prop.item.currency }}
                      </div>
                    </div>
                    <div class="authorization-id">
                      {{ prop.item.id }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template #customer_info="prop">
            <div
              class="customer-info-column fs-mask" v-if="(prop.item.consumer.first_name
                && prop.item.consumer.last_name) || prop.item.consumer.email"
            >
              <div class="name-and-email">
                <div class="name">
                  <div v-if="prop.item.consumer.first_name || prop.item.consumer.last_name">
                    {{ prop.item.consumer.first_name }} {{ prop.item.consumer.last_name }}
                  </div>
                  <div v-else class="empty-placeholder">
                    —
                  </div>
                </div>
                <div class="email">
                  <div v-if="prop.item.consumer.email">
                    {{ prop.item.consumer.email }}
                  </div>
                  <div v-else class="empty-placeholder">
                    —
                  </div>
                </div>
              </div>
            </div>
            <div class="empty-placeholder" v-else>
              —
            </div>
          </template>
          <template #authorization_date="prop">
            <ToolTip
              placement="top"
              :content="formatter({type : 'timeWithZone', data: prop.item.created_at})"
              :dismiss-on-click="true"
            >
              {{ formatter({type : 'zonedDate', data: prop.item.created_at}) }}
            </ToolTip>
          </template>
          <template #card="prop">
            <ToolTip
              placement="top"
              :content="$t('cards.brands.' + prop.item.payment_instrument.card.brand)"
              :dismiss-on-click="true"
            >
              <div class="payment-method-column">
                <component class="card-logo" :is="cardLogoForCardBrand(prop.item.payment_instrument.card.brand)" />
                <div class="payment-method">
                  {{ "**** " + prop.item.payment_instrument.card.last_four }}
                </div>
              </div>
            </ToolTip>
          </template>
        </TableView>

        <PortalFooter />
      </div>
    </div>
    <ModalPopup :show="showSubmitPopup">
      <div slot="body">
        <ExportsPopUp
          :on-cancel="() => {showSubmitPopup = false}"
        />
      </div>
    </ModalPopup>
  </div>
</template>

<script>

import SidebarNav from "@/components/SidebarNav";
import {mapActions, mapGetters} from "vuex";
import {SortBy, Sort, ResourceType} from "@/api/paymentApi";
import TableView from "@/components/common/TableView";
import TableFilterOption from "@/components/common/TableFilterOption";
import Search from "@/components/common/Search";
import {portalCheckMixin} from "@/mixin/portalPageCheck";
import ModalPopup from "@/components/common/ModalPopup";
import ExportsPopUp from "@/components/exports/ExportsPopUp";
import {navigateWithParam} from "@/router";
import {RouteConstants} from "@/router/routeConstants";
import SearchBar from "@/components/SearchBar";
import PortalFooter from "@/components/portal/PortalFooter.vue";
import {DateFilters, PendoPrefixes} from "@/app/utils/common/constants";
import ExportButton from "@/components/common/ExportButton.vue";
import {exportMixin} from "@/mixin/exportMixin";
import Formatter from "@/app/utils/common/functions/formatter";
import {cardBrandLogo} from "@/app/utils/common/helpers/cardBrandLogo";
import authorizations from "@/api/Authorizations";
import ToolTip from "@/components/common/ToolTip.vue";
import {
  getDatePeriodFromFilter,
} from "@/app/utils/common/functions/dateUtils";

export default {
  name: 'AuthorizationView',
  components: {
    ToolTip,
    ExportButton,
    SearchBar, Search, TableView, SidebarNav, TableFilterOption, ModalPopup, ExportsPopUp, PortalFooter},
  mixins: [portalCheckMixin, exportMixin],
  data: () => {
    return {
      authorizations: [],
      offsetId: null,
      listElm: null,
      pageSize: 15,
      dateFilter: {
        startDate: undefined,
        endDate: undefined,
      },
      selectedStatus: [],
      allItemsLoaded: false,
      loading: {
        all: false,
        export: false
      },
      sidebarNavCollapsed: false,
      count: 0,
      searchQuery: "",
      searchResults: [],
      currentTab: "ALL",
      tabs: [
        {
          name: "ALL",
        },
        {
          name: "SUCCESS",
        },
        {
          name: "FAILED",
        }
      ],
      authorizationStatusFilter: [
        {
          name: "SUCCEEDED",
        },
        {
          name: "PENDING",
        },
        {
          name: "FAILED",
        },
        {
          name: "CANCELED",
        },
      ],
      showSubmitPopup: false,
      tableFilterOptionConfig: {},
    }
  },
  computed: {
    ...mapGetters('app', ['getMerchantId', 'isAdminMode']),
    authorizationsCount() {
      return this.authorizations.length + " / " + this.count;
    },
    filteredAuthorizations() {
      return this.searchQuery === ""
          ? this.authorizations
              .filter(x => (this.setSelectedStatus(this.currentTab) || [x.status]).includes(x.status))
          : this.searchResults;
    },
    getColumns() {
      let columns = {};
      columns['authorization'] = 'authorizations.columns.authorization'
      columns['customer_info'] = 'authorizations.columns.customer_info'
      columns['authorization_date'] = 'authorizations.columns.authorization_date'
      columns['card'] = 'authorizations.columns.card'
      return columns;
    },
  },
  mounted() {
    window.cbStorage.setItem('dateFilter', DateFilters.MONTH_TO_DATE);
    this.portalChecks().then(() => {
      this.initialize();
    });
  },
  methods: {
    formatter: Formatter.format,
    ...mapActions('app', ['updateAppState']),
    setHourOfTheDay: Formatter.setHourOfTheDay,
    initialize(forceLoad = false) {
      ({ startDate: this.dateFilter.startDate, endDate: this.dateFilter.endDate } =
          getDatePeriodFromFilter(DateFilters.MONTH_TO_DATE))

      this.tableFilterOptionConfig = {
        statusFilterOption: this.authorizationStatusFilter
      }

      this.currentTab = window.cbStorage.getItem("cb_payments_authorizations_current_tab") || "ALL"
      this.selectedStatus = this.setSelectedStatus(this.currentTab)

      this.listElm = document.querySelector('#infinite-list');
      this.listElm?.addEventListener('scroll', () => {
        if ((this.listElm.scrollTop + this.listElm.clientHeight + 1 >= this.listElm.scrollHeight) && this.listElm.scrollTop !== 0 && !this.loading.all) {
          this.loadMore();
        }
      });

      this.loadMore(forceLoad);
    },
    async loadMore(forceLoad = false) {
      if ((this.allItemsLoaded === true || this.searchQuery !== '') && !forceLoad) return;

      this.loading.all = true;

      let response;
      let params = this.getCommonQueryParams();
      try {
        response = await authorizations.listMerchantAuthorizations(this.getMerchantId, params.cursor, params.size,
            params.sort_by, params.sort, params.start_date, params.end_date, params.status);
      } catch (e) {
        this.$danger("Could not load authorization!")
        this.loading.all = false;
        return;
      }
      this.offsetId = response.cursor;
      this.count = response.count;
      this.authorizations = this.authorizations.concat(response.authorizations)

      if (response.authorizations.length < this.pageSize) {
        this.allItemsLoaded = true;
      }
      this.loading.all = false;
      this.searchAuthorizations(this.searchQuery);
    },
    rowClicked: function (id, data) {
      navigateWithParam(RouteConstants.AUTHORIZATION_DETAILS, {authorizationId: id, data}, {
        ...this.$route.query,
        consumerId: data.consumer.id
      })
    },
    getCommonQueryParams() {
      return {
        cursor: this.offsetId,
        size: this.pageSize,
        sort_by: SortBy.ID,
        sort: Sort.DESC,
        start_date: this.dateFilter.startDate,
        end_date: this.dateFilter.endDate,
        status: this.selectedStatus
      }
    },
    applyFilter: function (filter) {
      this.authorizations = []
      this.searchResults = [];
      this.offsetId = null
      this.allItemsLoaded = false;
      this.count = 0;
      this.selectedStatus = filter.statusFilter.flatMap(x => this.setSelectedStatus(x));
      this.dateFilter.startDate = filter.dateFilterValue.startDate;
      this.dateFilter.endDate = filter.dateFilterValue.endDate;
      this.loadMore(true)
      this.listElm.scrollTo(0, 0);
    },
    searchAuthorizations: function (value) {
      this.searchQuery = value;
      this.searchResults = this.authorizations.filter(x => x.id.toLowerCase().includes(value.toLowerCase()))
    },
    clearFilterFromEmptyPlaceholder: function () {
      this.$refs.tableFilterOptionComponent.clearFilter();
    },
    setSelectedStatus: function (nextTab) {
      if (nextTab === "ALL") {
        return null;
      } else if (nextTab === "SUCCESS") {
        return [ "SUCCEEDED" ];
      } else {
        return [nextTab];
      }
    },
    switchTab: function (nextTab) {
      if (this.currentTab === nextTab) {
        return
      }
      this.selectedStatus = this.setSelectedStatus(nextTab);
      this.currentTab = nextTab
      window.cbStorage.setItem("cb_payments_authorizations_current_tab", this.currentTab)
      this.authorizations = [];
      this.searchResults = [];
      this.offsetId = null
      this.allItemsLoaded = false;
      this.count = 0;
      this.loadMore(true)
      this.listElm?.scrollTo(0, 0);
    },
    tooltipContent() {
      if (this.searchQuery !== '') {
        return "Clear search to export authorization";
      }
      if (this.authorizations.length === 0) {
        return "No authorizations to export";
      }
    },
    getCreateExportParams() {
      return {
        resource_type: ResourceType.AUTHORIZATION,
        authorization_filter: {
          ...this.getCommonQueryParams(),
          cursor: undefined,
          size: undefined,
          requested_from: "Authorizations",
        },
      };
    },
    getElementId(id) {
      return PendoPrefixes.CBpay + this.$route.name + '-' + id.toLowerCase();
    },
    cardLogoForCardBrand(cardBrand) {
      return cardBrandLogo[cardBrand] || cardBrandLogo['UNKNOWN'];
    },
  },
}

</script>

<style lang="scss">
.main-container {
  background: $neutral_50;
  height: 100%;
  overflow: scroll;
}

.authorizations-container {
  left: 256px;
  top: 0;
  position: relative;
  width: calc(100% - 256px);
  padding: 20px 30px 30px 30px;
  transition: all 0.2s ease-in;
  justify-content: center;
  display: flex;

  & .authorizations-sub-container{
    max-width: 1360px;
    width: 100%;
  }

  & .table-header-description {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $neutral_600;
    text-align: left;
    padding: 5px 0px 20px 0px;
  }

  & .table-header {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    padding-top: 20px;
    color: $neutral_1000;
    text-align: left;
  }

  & .nav {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: $weight_bold;
    background-color: transparent;
    padding-left: 0;
    border-bottom: 1px solid $neutral_200;
    margin-bottom: 16px;

    &:after {
      content: '';
      height: 2px;
      background-color: $color_white;
    }

    & .nav-item {
      color: $neutral_600;
      display: flex;
      cursor: pointer;
      align-items: start;
      margin-right: 16px;

      @media screen and (min-width: 801px) {
        padding: 12px 16px;
      }

      @media screen and (max-width: 800px) {
        padding: 10px 16px;
      }

      &:hover {
        background-color: $primary_fill_light;
      }

      &.disabled:hover {
        background-color: $color-white;
        cursor: not-allowed;
      }
    }

    & .nav-item-active {
      color: $primary_600;
      border-bottom: 2px solid $primary_400;

      & svg {
        margin-right: 10px;
        color: $primary_text;
      }
    }
  }

  &.authorizations-container-sidebar-collapse {
    left: 86px;
    width: calc(100% - 86px);
  }

  .header-container {
    background-color: $color_white;
    width: 100%;
    height: fit-content;
    border: 1px solid $neutral_100;
    border-radius: 6px 6px 0px 0px ;
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding: 15px 25px 5px 25px;

    & .authorizations-header-count{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $neutral_800;
      width: 100%;
      text-align: left;
      align-self: center;
      padding-bottom: 10px;
      display: flex;

      & .showing-count-top {
        display: inline-block;
        justify-content: left;

        & .pagination {
          font-weight: $weight_bold;
        }
      }

      & .loader {
        display: inline-block;
        width: 30px;
        height: 30px;
        border: 3px solid $neutral_100;
        border-radius: 50%;
        border-top-color: #fff;
        animation: spin 1s ease-in-out infinite;
        justify-content: center;
      }


      @keyframes spin {
        to {
          transform: rotate(360deg);
        }
      }

      @-webkit-keyframes spin {
        to {
          transform: rotate(360deg);
        }
      }

      .hide-loader {
        display: none;
        visibility: hidden;
      }

    }

    & .c-button {
      width: fit-content;
      height: 32px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      margin-left: 15px;
      display: flex;
      flex-direction: row;
      padding: 0;

      & img{
        margin-right: 5px;
      }
    }
  }

  & .authorization-column {
    display: flex;
    flex-direction: row;
    width: 100%;

    & .c-tooltip {
      width: 100%;
    }

    & .status-tag-container {
      display: flex;
      text-align: left;
      min-width: 70px;
      flex-direction: row;
      justify-content: space-between;
    }

    & .status {
      font-size: 12px;
      padding: 2px 10px;
      border-radius: 10px;
      height: fit-content;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }

    & .success-status {
      background-color: $success_100;
      color: $success_600;
    }

    & .failure-status {
      background-color: $danger_50;
      color: $danger_800;
    }

    & .canceled-status {
      background-color: $warn_50;
      color: $warn_800;
    }

    & .progress-status {
      background-color: $primary_100;
      color: $primary_800;
    }

    & .amount-and-txid {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      margin-left: 8px;

      & .amount_box {
        font-size: 14px;
        font-weight: $weight-medium;
        line-height: 20px;
        color: $neutral_1000;
        display: flex;
        flex-direction: row;

        & .amount {
          margin-right: 5px;
        }
      }

      & .authorization-id {
        font-weight: $weight_normal;
        color: $neutral_400;
        font-size: 12px;
        line-height: 20px;

      }
    }
  }

  & .customer-info-column {
    display: flex;

    & .name-and-email {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;

      & .name {
        font-size: 14px;
        font-weight: $weight-normal;
        line-height: 20px;
        color: $neutral_800;
      }

      & .email {
        font-weight: $weight_normal;
        color: $neutral_400;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }

  & .type-column {
    display: flex;
    min-height: 60px;
    align-items: center;

    & .type {
      display: flex;
      flex-direction: row;
      align-items: start;
      font-size: 14px;
      font-weight: $weight-normal;
      line-height: 20px;
      color: $neutral_800;
      width: 100%;
      justify-content: start;

      & .empty-placeholder {
        color: $neutral_300;

        &.amount-container {
          padding-right: 30px;
        }
      }

      & .no-cust-records-placeholder {
        color: $neutral_300;
        padding-left: 20px;
      }

      & .error_message {
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        flex-direction: column;
        align-items: start;
      }
    }
  }

  & .payment-method-column {
    display: flex;

    & .payment-method {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: start;
      font-size: 14px;
      font-weight: $weight-normal;
      line-height: 20px;
      color: $neutral_800;
    }

    & .card-logo {
      align-self: center;
      margin-right: 7px;
    }
  }

  & .search-container{
    width: 320px;
    margin-bottom: 16px;
  }
}
</style>