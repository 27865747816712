<template>
  <div class="main-container">
    <SidebarNav @update:sidebarNavCollapsed="sidebarNavCollapsed = $event" />
    <SearchBar :sidebar-nav-collapsed="sidebarNavCollapsed" />
    <div
      class="notifications-container"
      :class="{['notifications-container-sidebar-collapse']: sidebarNavCollapsed}"
    >
      <div class="notifications-sub-container">
        <div class="table-header">
          {{ $t('notifications.title') }}
        </div>

        <div class="nav">
          <div
            class="nav-item"
            v-for="tab in tabs"
            :key="tab.name"
            :class="{'nav-item-active': tab.name === currentTab, [tab.name]: true}"
            @click="() => switchTab(tab.name)"
            :id="getElementId('tab-' + tab.name)"
          >
            <span>{{ $t('notifications.tabs.' + tab.name) }}</span>
          </div>
        </div>
        <TableFilterOption
          :disabled="loading"
          :table-label="tableLabel"
          :current-tab="currentTab"
          :enable-filters="enabledFilters"
          :date-filter-duration="dateFilterOption"
          :key="tableLabel"
          v-bind="tableFilterOptionConfig"
          @childApplyFilter="applyFilter"
          @childClearFilter="clearFilter"
        >
          <div v-if="showClear" class="dismiss-button" slot="dismiss_all">
            <span class="clickable" @click="() => clearTodoPopup = true" :id="getElementId('todo-clear_all')">{{ $t('notifications.clearList') }} </span>
          </div>
        </TableFilterOption>
        <c-modal
          :control-variable="clearTodoPopup"
          :primary-action="$t('global.action.confirm')"
          :secondary-action="$t('global.action.cancel')"
          :heading="$t('clearTodos.title')"
          :show-icon="true"
          variant="danger"
          @primary="clear"
          @close="() => clearTodoPopup = false"
          :description="$t('clearTodos.description')"
        />
        <NotificationTable
          :visible-audits="visibleAudits"
          :hide-audit="hideAudit"
          :current-tab="currentTab"
          :loading="loading"
          :filter-applied="filterApplied"
          :all-items-loaded="allItemsLoaded"
        />
      </div>
    </div>
  </div>
</template>

<script>

import SidebarNav from "@/components/SidebarNav";
import {mapGetters} from "vuex";
import TableFilterOption from "@/components/common/TableFilterOption";
import {portalCheckMixin} from "@/mixin/portalPageCheck";
import {ResourceType, Sort, SortBy} from "@/api/paymentApi";
import Formatter from "@/app/utils/common/functions/formatter";
import SearchBar from "@/components/SearchBar";
import audits from "@/api/audits";
import _ from "lodash";
import {DateFilters, LocalStorageKeys, PendoPrefixes} from "@/app/utils/common/constants";
import {navigateTo} from "@/router";
import locales from "@/i18n/locales";
import NotificationTable from "@/components/notifications/NotificationTable.vue";
import {
  getDateTimeFromJSDate,
  getDateTimeFromObjectToUTCToJSDate,
  getDateTimeNow, getMonthsAgoRange
} from "@/app/utils/common/functions/dateUtils";

export default {
  name: 'NotificationView',
  components: {
    NotificationTable,
    SearchBar, SidebarNav, TableFilterOption
  },
  mixins: [portalCheckMixin],
  data: () => {
    return {
      todoEvents: ["DISPUTE_RESPONSE_REMINDER", "DISPUTE_PENDING", "DISPUTE_ADDITIONAL_EVIDENCE_REQUIRED", "COMPLIANCE_FORM_FILE_UPLOADED","MERCHANT_BALANCE_ACCOUNT_NEGATIVE", "FUND_ADDED_TO_RESERVE"],
      resourceType: null,
      eventsFilter: ["DISPUTE_PENDING", "DISPUTE_WON", "DISPUTE_LOST", "SETTLEMENT_APPROVED", "DEPOSIT_SUCCEEDED", "DEPOSIT_FAILED", "FUND_ADDED_TO_RESERVE"],
      currentAlertType: "all",
      currentEventsFilter: [],
      dateFilter: {
        startDate: undefined,
        endDate: undefined,
      },
      offsetId: null,
      pageSize: 15,
      visibleAudits: [],
      listElm: null,
      loading: false,
      allItemsLoaded: false,
      count: 0,
      filterApplied: false,
      clearTodoPopup : false,
      currentTab: "TODO",
      tabs: [
        {
          name: "TODO",
        },
        {
          name: "EVENTS",
        }
      ],
      sidebarNavCollapsed: false,
      tableFilterOptionConfig: {},
      isFilterDataPresent: false,
      dateFilterOption: 1,
    }
  },

  computed: {
    ...mapGetters('app', ['getMerchantId', 'isAdminMode']),
    enabledFilters() {
      return this.currentTab === "TODO" ? ['alertTypeFilter'] : ['dateFilter'];
    },
    tableLabel() {
      return this.currentTab === "TODO" ? "NotificationsTodo" : "NotificationsEvents";
    },
    showClear() {
      return this.currentTab === "TODO" && this.visibleAudits.length !== 0;
    },
    messageToExclude() {
      return this.currentTab === "TODO" ? null : "SETTLEMENT_PLATFORM";
    }
  },
  mounted() {
    this.portalChecks().then(() => {
      this.initialize();
    });
  },
  methods: {
    setHourOfTheDay: Formatter.setHourOfTheDay,
    initialize() {
      if (!this.isAdminMode) {
        navigateTo('error');
      }
      this.listElm = document.querySelector('#infinite-list');
      this.listElm?.addEventListener('scroll', () => {
        if ((this.listElm.scrollTop + this.listElm.clientHeight + 0.5 >= this.listElm.scrollHeight) && this.listElm.scrollTop !== 0 && !this.loading) {
          this.loadMore();
        }
      });
      this.initFilters();
      this.loadMore(true)
    },
    handleTodos: function(response) {
      let auditIdsToHide = localStorage.getItem(LocalStorageKeys.DISMISSIBLE_IDS) || [];
      this.visibleAudits = this.visibleAudits.concat(response.audits.filter(a => {
        if(a.event === 'FUND_ADDED_TO_RESERVE') {
          const transactionStatus = JSON.parse(a.content).state;
          if(transactionStatus === 'SUCCESS') auditIdsToHide.push(a.id);
        }
        let shouldHideAudit = _.includes(auditIdsToHide, a.id);
        return !shouldHideAudit;
      }));
    },
    handleEvents: function(response) {
      this.visibleAudits = this.visibleAudits.concat(response.audits)
      this.visibleAudits.forEach((va, index) => {
        if(va.event === 'FUND_ADDED_TO_RESERVE') {
          const transactionStatus = JSON.parse(va.content).state;
          if(transactionStatus === 'FAILED') this.visibleAudits = this.visibleAudits.filter(audit => audit.id !== va.id);
        }
        if (!va.dividerHeading) {
          va.dividerHeading = this.isToday(va.created_at) ? locales.en.notifications.today : this.getFullDate(va.created_at).toUpperCase();
        }
        if (!va.shouldShowDividerHeader) {
          va.shouldShowDividerHeader = index === 0 ? true : va.dividerHeading !== this.visibleAudits[index-1].dividerHeading;
        }
      })
    },
    clear: function () {
      this.visibleAudits.forEach(vi => {
        if(vi.resource_type !== ResourceType.COMPLIANCE_FORM) {
          this.hideAudit(vi.id);
        }
      });
      this.clearTodoPopup = false;
    },
    hideAudit: function (auditId) {
      let alreadyHiddenIds = localStorage.getItem(LocalStorageKeys.DISMISSIBLE_IDS) || [];
      alreadyHiddenIds = _.concat(alreadyHiddenIds, auditId);
      localStorage.setItem(LocalStorageKeys.DISMISSIBLE_IDS, alreadyHiddenIds);
      this.visibleAudits = this.visibleAudits.filter(i => i.id !== auditId);
    },
    async loadMore(forceLoad = false) {
      if ((this.allItemsLoaded === true) && !forceLoad) return;

      this.loading = true;
      let response;
      try {
        response = await audits.fetchAudits(this.getMerchantId, this.offsetId, this.pageSize, SortBy.ID, Sort.DESC,
              this.dateFilter.startDate, this.dateFilter.endDate, null, this.resourceType,
              this.messageToExclude, this.currentEventsFilter);
        this.offsetId = response.cursor;
        this.count = response.count;
        if(this.currentTab === "TODO") {
          this.handleTodos(response);
        } else {
          this.handleEvents(response);
        }
        if (response.audits.length < this.pageSize) {
          this.allItemsLoaded = true;
        }
        this.loading = false;
      } catch (e) {
        this.$danger("Could not load audits!")
        this.loading = false;
      }
    },
    isSameDay: function(date, currentDate) {
      const auditDate = getDateTimeFromJSDate(date).startOf('day');
      const currentDateTime = getDateTimeFromJSDate(currentDate).startOf('day');
      return auditDate.hasSame(currentDateTime, 'day');
    },
    getFullDate: function(createdAt) {
      return Formatter.format({type: 'zonedDate', data: createdAt})
    },
    isToday: function(auditDate) {
      const currentDate = getDateTimeNow();
      return this.isSameDay(auditDate, currentDate.toJSDate());
    },
    updateFilter: function (filter) {
      if (this.currentTab === "TODO") {
        this.currentAlertType = filter.alertTypeFilter;
        this.currentEventsFilter = this.initTodoEventsFilters(this.currentAlertType);
      } else {
        this.dateFilter.startDate = filter.dateFilterValue?.startDate;
        this.dateFilter.endDate = filter.dateFilterValue?.endDate;
      }
      this.loading = true;
      this.visibleAudits = [];
      this.offsetId = null;
      this.count = 0;
      this.allItemsLoaded = false;
      this.loadMore(true)
      this.listElm?.scrollTo(0, 0);
    },
    applyFilter: function (filter) {
      this.updateFilter(filter);
      this.filterApplied = true;
    },
    clearFilter: function (filter) {
      this.updateFilter(filter);
      this.filterApplied = false;
    },
    initTodoEventsFilters: function (appliedFilter) {
      switch (appliedFilter) {
        case "new_dispute" :
          return new Array("DISPUTE_PENDING")
        case "dispute_reminders":
          return new Array("DISPUTE_RESPONSE_REMINDER")
        case "additional_evidence":
          return new Array("DISPUTE_ADDITIONAL_EVIDENCE_REQUIRED")
        case "compliance_form":
          return new Array("COMPLIANCE_FORM_FILE_UPLOADED")
        case 'negative_balance':
          return new Array('MERCHANT_BALANCE_ACCOUNT_NEGATIVE')
        case 'fund_added':
          return new Array('FUND_ADDED_TO_RESERVE')
        default:
          return this.todoEvents;
      }
    },
    initFilters: function () {
      if(this.currentTab === "EVENTS") {
        window.cbStorage.setItem("dateFilter", DateFilters.ONE_MONTH);
        this.currentEventsFilter = this.eventsFilter;
        this.dateFilter.startDate = getMonthsAgoRange(1).startDate;
        this.resourceType = null;
        this.dateFilterOption = 1;
      }
      if(this.currentTab === "TODO") {
        this.dateFilter.startDate = getMonthsAgoRange(2).startDate
        this.currentEventsFilter = this.initTodoEventsFilters(this.currentAlertType);
        this.resourceType = null;
      }
      this.dateFilter.endDate = getDateTimeFromObjectToUTCToJSDate();
    },
    switchTab: function (nextTab) {
      if (this.currentTab === nextTab) {
        return
      }
      this.currentTab = nextTab
      this.clearData()
      this.initFilters();
      this.loadMore(true)
      this.listElm?.scrollTo(0, 0);
    },
    clearData: function () {
      this.offsetId = null
      this.visibleAudits = []
      this.allItemsLoaded = false
      this.count = 0
      this.filterApplied = false;
      this.currentEventsFilter = []
    },
    getElementId(id) {
      return PendoPrefixes.CBpay + this.$route.name + '-' + id.toLowerCase();
    }
  },
}

</script>

<style lang="scss">

.main-container {
  background: $neutral_50;
  height: 100%;
  overflow: scroll;
}

.notifications-container {
  left: 256px;
  top: 0;
  position: relative;
  width: calc(100% - 256px);
  padding: 20px 30px 30px 30px;
  transition: all 0.2s ease-in;
  justify-content: center;
  display: flex;

  & .notifications-sub-container{
    max-width: 1360px;
    width: 100%;
  }

  & .nav {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: $weight_bold;
    background-color: transparent;
    padding-left: 0;
    border-bottom: 1px solid $neutral_200;
    margin-bottom: 16px;

    &:after {
      content: '';
      height: 2px;
      background-color: $color_white;
    }

    & .nav-item {
      display: flex;
      cursor: pointer;
      align-items: start;
      margin-right: 16px;

      @media screen and (min-width: 801px) {
        padding: 12px 16px;
      }

      @media screen and (max-width: 800px) {
        padding: 10px 16px;
      }

      &:hover {
        background-color: $primary_fill_light;
      }

      &.disabled:hover {
        background-color: $color-white;
        cursor: not-allowed;
      }
    }

    & .nav-item-active {
      color: $primary_text;
      border-bottom: 2px solid $primary_text;

      & svg {
        margin-right: 10px;
        color: $primary_text;
      }
    }
  }

  & .table-header {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: $neutral_1000;
    text-align: left;
    padding: 20px 0;
  }

  &.notifications-container-sidebar-collapse {
    left: 86px;
    width: calc(100% - 86px);
  }

  & .dismiss-button {
    text-align: right;
    justify-content: right;
    align-self: center;
    display: flex;
    flex: max-content;
    font-weight: $weight_medium;
  }
}

</style>