/*eslint-disable @typescript-eslint/explicit-module-boundary-types*/
import {TabForAddFunds} from "@/app/utils/common/constants";
import i18n from '@/i18n'
import {isNullOrEmpty} from "@/app/utils/common/functions/functions";

const balanceReserveDefaultState = () =>  {
    return {
        errors: {
            selectBankAccount: null,
            enterAmount: null,
            refundReserve: null,
            chargebackReserve: null
        },
    //fees and amount are stored in cents
        data:
        {
            selectedPaymentInstrumentForDirectDebitFundTransfer: {
                value: null,
                valid : false
            },
            currentAmountInChargebackReserve: {
                value: null
            },
            currentAmountInRefundReserve: {
                value: null
            },
            pendingAmountToRefundReserve: {
                value: null,
            },
            pendingAmountToChargebackReserve: {
                value: null
            },
            amountAddedForRefundReserve: {
                value: 0,
                valid: false,
            },
            amountAddedForChargebackReserve: {
                value: 0,
                valid: false,
            },
            amountToBeHeldForUpfrontReserve: {
                value: 0
            }
        }
    }
};

const getters = {
    getCurrentAmountInRefundReserve: state => state.data.currentAmountInRefundReserve.value,
    getPendingAmountToRefundReserve: state => state.data.pendingAmountToRefundReserve.value,
    getCurrentAmountInChargebackReserve: state => state.data.currentAmountInChargebackReserve.value,
    getPendingAmountToChargebackReserve: state => state.data.pendingAmountToChargebackReserve.value,
    getAmountAddedForRefundReserve: state => state.data.amountAddedForRefundReserve.value,
    getAmountAddedForChargebackReserve: state => state.data.amountAddedForChargebackReserve.value,
    getSelectedPaymentInstrumentForDirectDebitFundTransfer: state =>
        state.data.selectedPaymentInstrumentForDirectDebitFundTransfer.value,
    getAmountToBeHeldForUpfrontReserve: state => state.data.amountToBeHeldForUpfrontReserve.value,
    isValidSelectBankAccount(state) {
        return state.data.selectedPaymentInstrumentForDirectDebitFundTransfer.valid;
    },
    isValidEnterAmount(state) {
        return state.data.amountAddedForRefundReserve.valid && state.data.amountAddedForChargebackReserve.valid;
    },
    getSelectBankAccountError: state => state.errors.selectBankAccount,
    getEnterAmountError: state => state.errors.enterAmount,
    getErrorForRefundReserve: state => state.errors.refundReserve,
    getErrorForChargebackReserve: state => state.errors.chargebackReserve,
}

const validateSelectBankAccount = (getters, commit) => {
    const selectedPaymentInstrument = getters.getSelectedPaymentInstrumentForDirectDebitFundTransfer;
    if (isNullOrEmpty(selectedPaymentInstrument)) {
        commit('setInValid', 'selectedPaymentInstrumentForDirectDebitFundTransfer')
        return ;
    }
    commit('setValid', 'selectedPaymentInstrumentForDirectDebitFundTransfer')
}
const generateErrorForSelectBankAccount = (getters, commit) => {
    if (!getters.isValidSelectBankAccount) {
        commit('setError', {key: 'selectBankAccount', error: i18n.t('addFundsToReserve.actionMessage.selectBankAccountError')});
    }
}
const isValidReserve = (getters, commit, reserveType) => {
    const currentAmount = getters[`getCurrentAmountIn${reserveType}`];
    const addedAmount = getters[`getAmountAddedFor${reserveType}`];
    if (currentAmount < 0 && addedAmount < Math.abs(currentAmount)) {
        commit('setInValid', `amountAddedFor${reserveType}`);
        return {reason: "NEGATIVE_BALANCE"}
    } else if(reserveType === "ChargebackReserve" && addedAmount < getters.getAmountToBeHeldForUpfrontReserve) {
        commit('setInValid', `amountAddedFor${reserveType}`);
        return {reason: "MINIMUM_UPFRONT_FEE"}
    } else if (getters.getAmountAddedForChargebackReserve === 0 && getters.getAmountAddedForRefundReserve === 0) {
        commit('setInValid', `amountAddedFor${reserveType}`);
        return {reason: "ADDED_AMOUNT"}
    } else {
        commit('setValid', `amountAddedFor${reserveType}`);
        return {reason: null}
    }
};
const generateErrorsReserve = (commit, getters, rootGetters) => {
    const isValidRefundReserve = isValidReserve(getters, commit, "RefundReserve");
    const isValidChargebackReserve = isValidReserve(getters, commit, "ChargebackReserve");
    if (isValidRefundReserve.reason === "NEGATIVE_BALANCE") {
        commit('setError', {key:'refundReserve' , error: i18n.t('addFundsToReserve.actionMessage.enterAmountErrorForNegBal', {
                amount: currencyFormat(rootGetters['app/getMerchantCurrency'], Math.abs(getters.getCurrentAmountInRefundReserve))
            })
        })
    }
    if (isValidChargebackReserve.reason === "NEGATIVE_BALANCE") {
        commit('setError', {
            key: 'chargebackReserve', error: i18n.t('addFundsToReserve.actionMessage.enterAmountErrorForNegBal', {
                amount: currencyFormat(rootGetters['app/getMerchantCurrency'], Math.abs(getters.getCurrentAmountInChargebackReserve))
            })
        })
    }
    if (isValidChargebackReserve.reason === "MINIMUM_UPFRONT_FEE") {
        commit('setError', {
            key: 'chargebackReserve', error: i18n.t('addFundsToReserve.actionMessage.enterAmountMinimumUpfrontFee', {
                amount: currencyFormat(rootGetters['app/getMerchantCurrency'], Math.abs(getters.getAmountToBeHeldForUpfrontReserve))
            })
        })
    }
    else if (isValidRefundReserve.reason ==="ADDED_AMOUNT" || isValidChargebackReserve.reason ==="ADDED_AMOUNT") {
        commit('setError', {key:'enterAmount' , error: i18n.t('addFundsToReserve.actionMessage.enterAmountError')})
    }
}

const currencyFormat = (currencyCode: string, value: number) => (
    currencyCode &&
    new Intl.NumberFormat('en-US', {
        style: 'currency',
        currencySign: 'accounting',
        currency: currencyCode,
    }).format(value / 100.0)
);

const actions = {
    updateBalanceReserveState({commit}, {key, value}) {
        value = isNullOrEmpty(value) ? 0: Number(value);
        commit('updateBalanceReserveState', {key, value})
    },
    setPaymentInstrumentForDirectDebitFundTransfer({commit, dispatch}, value) {
        commit('updateBalanceReserveState', {key: 'selectedPaymentInstrumentForDirectDebitFundTransfer', value: value});
        dispatch('setError', {key: 'selectBankAccount', error: null})
        dispatch('validate', 'SELECT_BANK_ACCOUNT')
    },
    setAmountAddedForRefundReserve({commit, dispatch}, value) {
        value = isNullOrEmpty(value) ? 0: Number(value) * 100.0;
        commit('updateBalanceReserveState', {key: 'amountAddedForRefundReserve', value: value})
        dispatch('setError', {key: 'enterAmount', error: null});
        dispatch('setError', {key: 'refundReserve', error: null});
        dispatch('validate', 'ENTER_AMOUNT')
    },
    setAmountAddedForChargebackReserve({commit, dispatch}, value) {
        value = isNullOrEmpty(value) ? 0: Number(value) * 100.0;
        commit('updateBalanceReserveState', {key: 'amountAddedForChargebackReserve', value: value})
        dispatch('setError', {key: 'chargebackReserve', error: null});
        dispatch('setError', {key: 'enterAmount', error: null});
        dispatch('validate', 'ENTER_AMOUNT')
    },
    validate({getters, commit}, tab) {
        if (tab === TabForAddFunds.SELECT_BANK_ACCOUNT) {
            validateSelectBankAccount(getters, commit)
        }
        if (tab === TabForAddFunds.ENTER_AMOUNT) {
            isValidReserve(getters, commit, "RefundReserve")
            isValidReserve(getters, commit, "ChargebackReserve")
        }
    },
    validateAndGenerateError({dispatch, commit, getters, rootGetters}, tab) {
        dispatch('resetErrors');
        if (tab === TabForAddFunds.SELECT_BANK_ACCOUNT) {
            generateErrorForSelectBankAccount(getters, commit)
        }
        if (tab === TabForAddFunds.ENTER_AMOUNT) {
            generateErrorsReserve(commit, getters, rootGetters)
        }
    },
    setError({commit}, {key, error}) {
        commit('setError', {key, error});
    },
    resetErrors({commit}) {
        commit('resetErrors')
    },
    resetState({commit}) {
        commit('resetState');
    }
};

const mutations = {
    updateBalanceReserveState(state, {key, value}) {
        state.data[key].value = value;
    },
    setValid(state, key) {
        state.data[key].valid = true;
    },
    setInValid(state, key) {
        state.data[key].valid = false;
    },
    setError(state, {key, error}) {
        state.errors[key] = error;
    },
    resetErrors(state) {
        state.errors = balanceReserveDefaultState().errors;
    },
    resetState(state) {
        state.data.amountAddedForChargebackReserve = balanceReserveDefaultState().data.amountAddedForChargebackReserve;
        state.data.amountAddedForRefundReserve = balanceReserveDefaultState().data.amountAddedForRefundReserve;
        state.data.selectedPaymentInstrumentForDirectDebitFundTransfer = balanceReserveDefaultState().data
            .selectedPaymentInstrumentForDirectDebitFundTransfer;
    }
};

export const merchantBalanceReserve = {
    namespaced: true,
    state: balanceReserveDefaultState(),
    getters,
    actions,
    mutations
};