<template>
  <div class="transaction-overview">
    <div class="title">
      Deductions
    </div>
    <div class="fields" v-if="transaction">
      <div class="column">
        <div v-if="['DEBIT'].includes(transaction.type) && transaction.payfac === payfac.ADYEN">
          <FieldValue
            :field-value="getReserveAmount()"
            :field-name="$t('fieldNames.reserveDeduction')"
          />
        </div>
        <div v-if="showFees()">
          <FieldValue
            v-for="fee in feeSplit.filter((_, index) => index % 2 !== 0)"
            :field-value="getFeeAmount(fee)"
            :field-name="getFeeName(fee)"
            :key="fee.name"
          />
        </div>
      </div>
      <div class="column">
        <div v-if="showFees()">
          <FieldValue
            v-for="fee in feeSplit.filter((_, index) => index % 2 === 0)"
            :field-value="getFeeAmount(fee)"
            :field-name="getFeeName(fee)"
            :key="fee.name"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FieldValue from "@/components/common/FieldValue.vue";
import Formatter from "@/app/utils/common/functions/formatter";
import {
  TransactionResponseDto,
  TransactionState, CardPricingType, PaymentInstrumentType, TransactionType, FeeSplitDetailsDto
} from "@/api/paymentApi";
import {mapGetters} from "vuex";
import { payfac } from "@/app/utils/common/constants";

const BASIS_PLUS_FIXED = "BASIS_PLUS_FIXED";

export default {
  name: "DeductionsSplit",
  components: {FieldValue},
  props: {
    transaction: {
      type: TransactionResponseDto,
      required: true,
    }
  },
  data() {
    return {
      feeSplit: []
    }
  },
  computed: {
    payfac() {
      return payfac
    },
    PaymentInstrumentType() {
      return PaymentInstrumentType
    },
    ...mapGetters('app', ['getRollingReserveConfig', 'getMerchantCurrency']),
  },
  mounted() {
    this.feeSplit = this.transaction?.fee_split;
    if (!this.feeSplit) {
      return;
    }
    this.feeSplit.push({
      type: BASIS_PLUS_FIXED,
      amount: this.feeSplit.find(fee => fee.type === FeeSplitDetailsDto.TypeEnum.FIXEDFEE)?.amount
        + this.feeSplit.find(fee => fee.type === FeeSplitDetailsDto.TypeEnum.BASISPOINTS)?.amount,
      currency: this.feeSplit.find(fee => fee.type === FeeSplitDetailsDto.TypeEnum.FIXEDFEE)?.currency,
      amount_in_merchant_currency: this.feeSplit.find(fee => fee.type === FeeSplitDetailsDto.TypeEnum.FIXEDFEE)?.amount_in_merchant_currency
        + this.feeSplit.find(fee => fee.type === FeeSplitDetailsDto.TypeEnum.BASISPOINTS)?.amount_in_merchant_currency
    });
    this.feeSplit = this.feeSplit.filter(fee => fee.type !== FeeSplitDetailsDto.TypeEnum.FIXEDFEE && fee.type !== FeeSplitDetailsDto.TypeEnum.BASISPOINTS);
  },
  methods: {
    getFeeName(fee) {
      // we will be showing the fixed fee + basis as blended fee for blended pricing flat rate
      // and CB Pay Fee for IC++
      if (fee.type === BASIS_PLUS_FIXED) {
        return this.getPricingType() === CardPricingType.BLENDEDPRICINGFLATRATE
          ? this.$t('fieldNames.fixed_fee') : this.$t('fieldNames.cb_pay_fee');
      }
      return this.$t('fieldNames.' + fee.type.toLowerCase());
    },
    getFeeAmount(fee) {
      if (!fee || !fee?.amount) {
        return '-';
      }
      return this.appendValueInMerchantCurrency(this.formatCurrency(fee.amount, fee.currency),
        fee.amount_in_merchant_currency);
    },
    getPricingType() {
      return this.transaction.pricing_type;
    },
    showFees() {
      return this.feeSplit;
    },
    formatCurrency(data, currencyCode) {
      return Formatter.format({type: 'currency', data, currencyCode}) + " " + currencyCode
    },
    appendValueInMerchantCurrency(prefix, value) {
      if (prefix && value
        && [TransactionType.DEBIT, TransactionType.REFUND].includes(this.transaction.type)
        && this.transaction.currency !== this.getMerchantCurrency) {
        let amountInMerchantCurrency = this.formatCurrency(value, this.getMerchantCurrency);
        return prefix + " (" + amountInMerchantCurrency +")";
      }
      return prefix;
    },
    getReserveAmount() {
      let transactionReserve = this.transaction.transaction_reserve;
      if (!transactionReserve || [TransactionState.FAILED, TransactionState.NOTREFUNDED, TransactionState.INITIATIONATPAYFACFAILED].includes(this.transaction.state)) {
        return null;
      }
      return this.appendValueInMerchantCurrency(this.formatCurrency(transactionReserve.amount, this.transaction.currency),
        transactionReserve.amount_in_merchant_currency);
    },
  }
}
</script>

<style lang="scss">
.transaction-overview {
  & .title {
    text-align: left;
    font-size: 16px;
    font-weight: $weight-bold;
  }

  & .warning {
    margin-top: 12px;
    display: flex;
    background: $info_50;
    border: 0.5px solid $info_100;
    border-radius: 4px;
    padding: 6px;
    gap: 10px;
    color: $primary_600;
    line-height: 18px;

    & .warning-text {
      color: $neutral-800;
      height: 18px;
      font-size: 12px;
    }
  }

  & .fields {
    display: flex;

    & .column {
      text-align: left;
      flex: 1;
    }

    & .consumer-email {
      & a {
        text-decoration: none;
      }
    }

    & .parent-transaction-container {
      display: flex;
      gap: 4px;

      & .source-transaction {
        color: $primary_600;
        font-weight: $weight_medium;
        cursor: pointer;
      }
    }

    & .respond-by {
      & .field-value {
        color: #DE3618;
      }
    }
  }
}
</style>