<template>
  <div class="transaction-detail-container">
    <SidebarNav @update:sidebarNavCollapsed="sidebarNavCollapsed = $event" />
    <SearchBar :sidebar-nav-collapsed="sidebarNavCollapsed" />
    <div
      class="transaction-detail-content"
      :class="{['transaction-detail-content-sidebar-collapse']: sidebarNavCollapsed}"
    >
      <div class="transaction-detail-sub-content">
        <div class="options-bar">
          <div class="back-to-transactions" @click="navigateBack()" :id="getElementId('go_back')">
            <img src="@/app/assets/images/back.svg" alt="Back to transactions icon" />
            <span>{{ backButtonText }}</span>
          </div>
        </div>
        <div class="error" v-if="transaction && transaction.error_reason">
          <img src="@/app/assets/images/red-exclamation.svg" alt="Error" />
          {{ errorReason }}
        </div>
        <div class="loading-message" v-if="loading">
          Fetching transaction...
        </div>
        <div class="main-layout-container" v-else>
          <div class="cl-dock__main">
            <div class="layout-container">
              <div class="cards">
                <div class="card-container first-card">
                  <div class="card">
                    <TransactionInfo :transaction="transaction" />
                  </div>
                </div>
                <div class="card-container" id="overview">
                  <div class="card">
                    <TransactionOverview
                      :transaction="transaction"
                      :source-transaction="getSourceTransaction()"
                      :settlement="settlement"
                      :dispute="dispute"
                    />
                  </div>
                </div>

                <div class="card-container" id="deductions" v-if="['DEBIT'].includes(transaction.type)">
                  <div class="card">
                    <DeductionsSplit
                      :transaction="transaction"
                    />
                  </div>
                </div>

                <div class="card-container" v-if="['DEBIT', 'RESERVE'].includes(transaction.type)" id="payment">
                  <div class="card">
                    <PaymentInstrument :payment-instrument="transaction.payment_instrument" />
                  </div>
                </div>

                <div class="card-container" v-if="isDunningAttempt()" id="payment-retries">
                  <div class="card">
                    <PaymentRetries :transaction="transaction" />
                  </div>
                </div>

                <div class="card-container" v-if="['DEBIT', 'RESERVE'].includes(transaction.type)" id="refunds">
                  <div class="card">
                    <TransactionRefunds
                      :transaction="transaction"
                      :refunds="refunds" @refundCreated="refundCreated"
                    />
                  </div>
                </div>

                <div class="card-container" id="audit-logs">
                  <div class="card">
                    <RefundAuditLogs
                      v-if="['REFUND', 'PAYMENT_REVERSAL'].includes(transaction.type)"
                      :transaction="transaction"
                      :refunds="refunds" @refundCreated="refundCreated"
                      :source-transaction="sourceTransaction"
                    />
                    <PaymentReturnAuditLogs
                      v-if="transaction.type === 'PAYMENT_RETURN'"
                      :transaction="transaction"
                      :refunds="refunds"
                      :source-transaction="sourceTransaction"
                    />
                    <TransactionAuditLogs
                      v-if="['DEBIT', 'RESERVE', 'RESERVE_ADJUSTMENT_DEBIT', 'RESERVE_ADJUSTMENT_CREDIT', 'RESERVE_TRANSFER'].includes(transaction.type)"
                      :transaction="transaction"
                      :source-transaction="sourceTransaction"
                      :dispute="dispute"
                      :refunds="refunds"
                    />
                    <ChargebackAuditLogs
                      v-if="isChargebackTransaction()"
                      :dispute="dispute"
                      :transaction="transaction"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cl-dock__sidebar">
            <TableOfContents :sections="sections" />
          </div>
        </div>
        <PortalFooter />
      </div>
    </div>
  </div>
</template>

<script>
import SidebarNav from "@/components/SidebarNav";
import {portalCheckMixin} from "@/mixin/portalPageCheck";
import {navigateTo} from "@/router";
import {mapGetters} from "vuex";
import transactions from "@/api/transactions";
import TransactionInfo from "@/components/transactions/TransactionInfo";
import refunds from "@/api/refunds";
import TransactionOverview from "@/components/transactions/TransactionOverview";
import TransactionAuditLogs from "@/components/transactions/TransactionAuditLogs";
import TransactionRefunds from "@/components/transactions/TransactionRefunds";
import {TransactionType} from "@/api/paymentApi";
import settlements from "@/api/settlements";
import SearchBar from "@/components/SearchBar";
import disputes from "@/api/disputes";
import RefundAuditLogs from "@/components/transactions/RefundAuditLogs";
import PaymentReturnAuditLogs from "@/components/transactions/PaymentReturnAuditLogs.vue";
import PortalFooter from "@/components/portal/PortalFooter.vue";
import { PendoPrefixes } from "@/app/utils/common/constants";
import TableOfContents from "@/components/common/TableOfContents.vue";
import locales from "@/i18n/locales";
import PaymentInstrument from "@/components/PaymentInstrument.vue";
import ChargebackAuditLogs from "@/components/transactions/ChargebackAuditLogs.vue";
import {RouteConstants} from "@/router/routeConstants";
import PaymentRetries from "@/components/transactions/PaymentRetries.vue";
import DeductionsSplit from "@/components/transactions/DeductionsSplit.vue";

export default {
  name: "TransactionDetailsView",
  components: {
    DeductionsSplit,
    PaymentRetries,
    PaymentInstrument,
    ChargebackAuditLogs,
    TableOfContents,
    PaymentReturnAuditLogs,
    RefundAuditLogs,
    TransactionRefunds,
    TransactionAuditLogs,
    TransactionOverview,
    TransactionInfo,
    SidebarNav,
    SearchBar,
    PortalFooter
  },
  mixins: [portalCheckMixin],
  data: function () {
    return {
      sidebarNavCollapsed: false,
      transaction: null,
      sourceTransaction: null,
      dunningParentTransaction: null,
      refunds: [],
      settlement: null,
      dispute: null,
      titles: [
        "profile",
        "address",
      ],
      sections: []
    }
  },
  computed: {
    ...mapGetters('app', ['getMerchantId']),
    loading() {
      return (this.transaction === null) || (this.transaction.settlement_id !== null && this.settlement === null)
    },
    errorReason() {
      return (this.transaction.error_reason || "").replaceAll("ReasonDescription: ", "");
    },
    backButtonText() {
      return this.$t('transactions.actions.backToTransactions');
    }
  },
  mounted() {
    this.portalChecks().then(() => {
      this.initialize();
    });
  },
  methods: {
    async initialize() {
      await this.getTransactionByConsumerId();
      if (this.transaction.type === TransactionType.DEBIT) {
        refunds.fetchRefundsForATransaction(this.getMerchantId, this.$route.params.transactionId)
            .then(response => {
              this.refunds = response.refunds;
            })
      }

      if (this.transaction.source_transaction_id) {
        transactions.fetchTransaction(this.getMerchantId, this.transaction.source_transaction_id)
          .then(response => {
              this.sourceTransaction = response;
          })
      }

      if (this.transaction.tags?.is_dunning_attempt) {
        transactions.fetchTransaction(this.getMerchantId, this.transaction.tags.parent_transaction_id)
            .then(response => {
              this.dunningParentTransaction = response;
            })
      }

      if (this.transaction.settlement_id) {
        settlements.fetchSettlementById(this.getMerchantId, this.transaction.settlement_id)
            .then(response => {
              this.settlement = response
            })
      }
      if (!this.isChargebackTransaction()) {
        disputes.listMerchantDisputes(
            this.getMerchantId, undefined, undefined, undefined, undefined, undefined, undefined,
            this.transaction.id, undefined)
            .then(response => {
              this.dispute = response.disputes[0]
            })
      } else {
        this.dispute = await disputes.fetchDisputeDetails(this.getMerchantId, this.transaction.tags.dispute_id);
      }

      this.sections = [
        {id: 'overview', title: locales.en.tableOfContent.overview, show: true},
        {
          id: 'payment',
          title: locales.en.tableOfContent.transaction.payment_instrument,
          show: this.transaction.payment_instrument.card != null || this.transaction.payment_instrument.bank_account != null
        },
        { id: 'deductions',
          title: locales.en.tableOfContent.transaction.deductions,
          show: this.transaction.type === TransactionType.DEBIT
        },
        {
          id: 'payment-retries',
          title: locales.en.tableOfContent.transaction.payment_retries,
          show: this.isDunningAttempt()
        },
        {id: 'refunds', title: locales.en.tableOfContent.transaction.refunds, show: this.transaction.type === 'DEBIT'},
        {id: 'audit-logs', title: locales.en.tableOfContent.transaction.auditLogs, show: true},
      ]
    },
    getTransactionByConsumerId: async function() {
      if (this.$route.query.consumerId) {
        this.transaction = await transactions.fetchConsumerTransaction(this.getMerchantId,
          this.$route.query.consumerId,
          this.$route.params.transactionId)
      } else {
        this.transaction = await transactions.fetchTransaction(this.getMerchantId, this.$route.params.transactionId)
      }
    },
    navigateBack: function () {
      const query = { ...this.$route.query };
      delete query.consumerId;
      navigateTo(RouteConstants.TRANSACTIONS, false, query);
    },
    isChargebackTransaction() {
      return [TransactionType.CHARGEBACKDEBIT, TransactionType.CHARGEBACKCREDIT, TransactionType.CHARGEBACKFEE].includes(this.transaction.type);
    },
    navigateTo: function (route) {
      navigateTo(route, false, this.$route.query);
    },
    getSourceTransaction() {
      return (this.sourceTransaction !== null) ? this.sourceTransaction : this.dunningParentTransaction;
    },
    refundCreated: async function () {
      this.refunds = (await refunds.fetchRefundsForATransaction(this.getMerchantId, this.$route.params.transactionId)).refunds
    },
    isDunningAttempt() {
      return this.transaction.dunning_attempts?.length > 0;
    },
    getElementId(id) {
      return PendoPrefixes.CBpay + this.$route.name + '-' + id;
    },
  },
}
</script>

<style lang="scss">
.transaction-detail-container {
  background: $neutral_50;
  height: 100%;
  overflow: scroll;

  & .loading-message {
    color: $primary_text_dark;
    margin-top: 52px;
  }

  & .error {
    background-color: $danger_50;
    color: $neutral_800;
    padding: 6px 8px;
    width: calc(75% - 16px);
    display: flex;
    gap: 10px;
    align-items: center;
    border: 0.5px solid $danger_100;
    border-radius: 6px;
    margin-bottom: 24px;
    text-align: left;
  }

  & .main-layout-container {

    display: flex;
    justify-content: space-between;
    width: 100%;

    & .cl-dock__main {
      width: calc(75% - 8px);
      margin-right: 32px;

      @media screen and (max-width: 700px) {
        width: 100%;
      }
    }

    & .cl-dock__sidebar {
      top: 50px;
      position: sticky;
      border-radius: 8px;
      width: calc(25% - 12px);
      align-self: flex-start;

      @media screen and (max-width: 700px) {
        display: none;
        width: 0;
      }
    }

    & .layout-container {
      width: 100%;
      overflow: auto;

      & .cards {
        width: 100%;

        & .card-container {
          scroll-margin-top: 70px;
          background-color: #FFFFFF;
          padding: 24px;
          border-radius: 6px;
          margin-top: 24px;

          & .card {
            width: 100%;
          }
        }

        & .first-card {
          margin-top: 0;
        }
      }

    }
  }

  & .transaction-detail-content {
    left: 256px;
    top: 0;
    position: relative;
    width: calc(100% - 256px);
    padding: 24px 32px;
    transition: all 0.2s ease-in;
    justify-content: center;
    display: flex;

    & .transaction-detail-sub-content {
      max-width: 1360px;
      width: 100%;
    }

    & .options-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 28px;

      & .back-to-transactions {
        cursor: pointer;
        color: $neutral_600;
        font-weight: $weight_medium;

        & span {
          margin-left: 8px;
        }
      }

      & .respond-buttons {
        display: flex;
        align-items: center;

        & button {
          margin-left: 16px;
          margin-bottom: 0;

          & .c-button__label {
            padding: 0;
          }
        }
      }
    }
  }
  & .transaction-detail-content-sidebar-collapse {
    left: 86px;
    width: calc(100% - 86px);
  }
}
</style>