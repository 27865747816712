<template>
  <div class="table-header-container">
    <div class="filter-container">
      <div class="filter-row">
        <!-- Date Filter 👇-->
        <c-dropdown
          :class="{'disabled': disabled}"
          :disabled="disabled" class="filter"
          v-if="enableFilters.includes('dateFilter')"
        >
          <c-button
            slot="trigger"
            class="filter-button"
            :id="getElementId('filter-date')"
          >
            <div class="filter-name-value-container">
              <div class="filter-name">
                {{ $t('filters.dateFilter.' + tableLabel) }}
              </div>
              <div class="filter-value">
                <div class="filter-value-item">
                  {{ $t('filters.dateFilter.' + currentDateFilter) }}
                </div>
              </div>
            </div>
            <img alt="dropdown" class="filter-icon" src="@/app/assets/images/dropdown.svg" />
          </c-button>

          <c-dropdown-item
            v-for="filter in dateFilterOption"
            :key="filter.name"
            :option="filter.name"
            class="filter-date-item-container"
            @click="() => switchDateFilter(filter.name)"
          >
            <img
              :class="{'filter-date-item-active': filter.name === currentDateFilter}"
              alt="check"
              class="filter-date-item-check"
              src="@/app/assets/images/check.svg"
            />
            <div>{{ $t('filters.dateFilter.' + filter.name) }}</div>
          </c-dropdown-item>
        </c-dropdown>
        <c-date-time-picker
          :value="getValueForDatePicker"
          class="filter"
          :class="{'disabled': disabled}"
          :max-date="dateSelectorLimit(-dateFilterDuration)[0]"
          :min-date="dateSelectorLimit(-dateFilterDuration)[1]"
          :use-time="false"
          :is-range="true"
          :zone="getTimezoneName"
          @update="update"
          v-if="enableFilters.includes('dateFilter')"
          :disabled="disabled"
        >
          <c-button slot="trigger" class="filter-button">
            <span class="c-mr--ti">
              {{ customDateFilterTitle }}
            </span>
            <img alt="calender" class="filter-icon" src="@/app/assets/images/calender.svg" />
          </c-button>
        </c-date-time-picker>
        <!-- Payment Method Status Filter 👇-->
        <c-dropdown
          :class="{'disabled': isStatusFilterDisabled}"
          :close-on-select="false"
          :disabled="isStatusFilterDisabled"
          class="filter"
          v-if="enableFilters.includes('statusFilter')"
        >
          <c-button
            slot="trigger"
            class="filter-button"
            :id="getElementId('filter-status')"
          >
            <div class="filter-name-value-container">
              <div class="filter-name">
                {{
                  $t('filters.statusFilter.' + tableLabel + '.' + 'status')
                }}{{ changeFilterBasedOnTabAndTable[0].length === 0 ? "" : ":" }}
              </div>
              <div class="filter-value">
                <div
                  v-if="changeFilterBasedOnTabAndTable[0].length === statusFilterOption.length"
                  class="filter-value-item"
                >
                  {{ $t('filters.statusFilter.ALL') }}
                </div>
                <div v-else-if="changeFilterBasedOnTabAndTable[0].length <= 2" class="filter-value-container">
                  <div
                    v-for="statusFilter in changeFilterBasedOnTabAndTable[0]" :key="statusFilter"
                    class="filter-value-item"
                  >
                    {{ $t('filters.statusFilter.' + statusFilter) }}
                  </div>
                </div>
                <div v-else class="filter-value-item">
                  {{ changeFilterBasedOnTabAndTable[0].length }} {{
                    $t('filters.statusFilter.selected')
                  }}
                </div>
              </div>
            </div>
            <img alt="dropdown" class="filter-icon" src="@/app/assets/images/dropdown.svg" />
          </c-button>

          <c-dropdown-item
            v-for="filter in statusFilterOption"
            :key="filter.name"
            :option="filter.name"
            class="filter-date-item-container"
            @click="manageFilter(currentStatusFilter, filter.name)"
          >
            <c-checkbox
              :checked="currentStatusFilter.includes(filter.name)"
              @click="manageFilter(currentStatusFilter, filter.name)"
            >
              {{ $t('filters.statusFilter.' + filter.name) }}
            </c-checkbox>
          </c-dropdown-item>
        </c-dropdown>
        <!--Type Filter 👇-->
        <c-dropdown
          :class="{'disabled': isTypeFilterDisabled}"
          :close-on-select="false"
          :disabled="isTypeFilterDisabled"
          class="filter"
          v-if="enableFilters.includes('typeFilter')"
        >
          <c-button
            slot="trigger"
            class="filter-button"
            :id="getElementId('filter-type')"
          >
            <div>
              <div class="filter-name-value-container">
                <div class="filter-name">
                  {{
                    $t('filters.typeFilter.' + tableLabel + '.' + 'type')
                  }}{{ changeFilterBasedOnTabAndTable[1].length === 0 ? "" : ":" }}
                </div>
                <div class="filter-value">
                  <div
                    v-if="changeFilterBasedOnTabAndTable[1].length === typeFilterOption.length"
                    class="filter-value-item"
                  >
                    {{ $t('filters.typeFilter.ALL') }}
                  </div>
                  <div v-else-if="changeFilterBasedOnTabAndTable[1].length <= 2" class="filter-value-container">
                    <div
                      v-for="typeFilter in changeFilterBasedOnTabAndTable[1]" :key="typeFilter"
                      class="filter-value-item"
                    >
                      {{ $t('filters.typeFilter.' + tableLabel + '.' + typeFilter) }}
                    </div>
                  </div>
                  <div v-else class="filter-value-item">
                    {{ changeFilterBasedOnTabAndTable[1].length }} {{
                      $t('filters.typeFilter.selected')
                    }}
                  </div>
                </div>
              </div>
            </div>
            <img alt="dropdown" class="filter-icon" src="@/app/assets/images/dropdown.svg" />
          </c-button>

          <c-dropdown-item
            v-for="filter in typeFilterOption"
            :key="filter.name"
            :option="filter.name"
            class="filter-date-item-container"
            @click="manageFilter(currentTypeFilter, filter.name)"
          >
            <c-checkbox
              :checked="currentTypeFilter.includes(filter.name)"
              @click="manageFilter(currentTypeFilter, filter.name)"
            >
              {{ $t('filters.typeFilter.' + tableLabel + '.' + filter.name) }}
            </c-checkbox>
          </c-dropdown-item>
        </c-dropdown>
        <!-- Payment Method Type Filter 👇-->
        <MultiSelectDropdown
          v-if="enableFilters.includes('paymentMethodTypeFilter')"
          :entity="FilterEntity.PaymentMethod"
          :update="(name) => manageFilter(currentPaymentMethodFilter, name)"
          :selected-list="currentPaymentMethodFilter"
          :options="paymentMethodFilterOption"
          :pendo-element-id="getElementId('filter-payment_method')"
          :disabled="disabled"
        />
        <!-- Amount Filter 👇-->
        <AmountFilter
          v-if="enableFilters.includes('amountFilter')"
          :set-value2="(event) =>{
            currentAmountInput2 = Number(event.target.value);
            applyAmountFilter(currentAmountFilter, currentAmountInput1,
                              currentAmountInput2, currentAmountFilterValue);
            checkDefaultFilters();
            checkPreviousFilterState();
          }"
          :set-value1="(event) =>{
            currentAmountInput1 = Number(event.target.value);
            applyAmountFilter(currentAmountFilter, currentAmountInput1,
                              currentAmountInput2, currentAmountFilterValue);
            checkDefaultFilters();
            checkPreviousFilterState();
          }"
          :current-operator="currentAmountFilter"
          :on-operator-change="(filterName) => {
            currentAmountFilter=filterName;
            applyAmountFilter(currentAmountFilter, currentAmountInput1,
                              currentAmountInput2, currentAmountFilterValue);
            checkDefaultFilters();
            checkPreviousFilterState();
          }"
          :amount-input2="currentAmountInput2"
          :amount-input1="currentAmountInput1"
          :disabled="disabled"
          :pendo-element-id="getElementId('filter-amount')"
          :legend="$t('filters.' + FilterEntity.Amount + '.transaction.label')"
        />

        <!-- Deposit Amount Filter 👇-->
        <AmountFilter
          v-if="enableFilters.includes('depositAmountFilter')"
          :set-value2="(event) =>{
            currentDepositAmountInput2 = Number(event.target.value);
            applyAmountFilter(currentDepositAmountFilter, currentDepositAmountInput1,
                              currentDepositAmountInput2, currentDepositAmountFilterValue);
            checkForAppliedDepositFilters(currentDepositAmountInput1, currentDepositAmountInput2)
            checkDefaultFilters();
            checkPreviousFilterState();
          }" 
          :set-value1="(event) =>{
            currentDepositAmountInput1 = Number(event.target.value);
            applyAmountFilter(currentDepositAmountFilter, currentDepositAmountInput1,
                              currentDepositAmountInput2, currentDepositAmountFilterValue);
            checkForAppliedDepositFilters(currentDepositAmountInput1, currentDepositAmountInput2)
            checkDefaultFilters();
            checkPreviousFilterState();
          }"
          :current-operator="currentDepositAmountFilter"
          :on-operator-change="(filterName) => {
            currentDepositAmountFilter=filterName;
            applyAmountFilter(currentDepositAmountFilter, currentDepositAmountInput1,
                              currentDepositAmountInput2, currentDepositAmountFilterValue);
            checkForAppliedDepositFilters(currentDepositAmountInput1, currentDepositAmountInput2)
            checkDefaultFilters();
            checkPreviousFilterState();
          }"
          :amount-input2="currentDepositAmountInput2"
          :amount-input1="currentDepositAmountInput1"
          :disabled="disabled"
          :pendo-element-id="getElementId('filter-deposit_amount')"
          :legend="$t('filters.' + FilterEntity.Amount + '.deposit.label')"
        />
        <!-- Alert Type Filter 👇-->
        <c-dropdown
          :class="{'disabled': disabled}"
          :disabled="disabled" class="filter"
          v-if="enableFilters.includes('alertTypeFilter')"
        >
          <c-button
            slot="trigger"
            class="filter-button"
            :id="getElementId('filter-alert_type')"
          >
            <div class="filter-name-value-container">
              <div class="filter-name">
                {{ $t('filters.alertTypeFilter.title') }}
              </div>
              <div class="filter-value">
                <div class="filter-value-item">
                  {{ $t('filters.alertTypeFilter.' + currentAlertTypeFilter) }}
                </div>
              </div>
            </div>
            <img alt="dropdown" class="filter-icon" src="@/app/assets/images/dropdown.svg" />
          </c-button>

          <c-dropdown-item
            v-for="filter in alertTypeFilterOption"
            :key="filter.name"
            :option="filter.name"
            class="filter-date-item-container"
            @click="() => manageAlertTypeFilter(filter.name)"
          >
            <img
              :class="{'filter-date-item-active': filter.name === currentAlertTypeFilter}"
              alt="check"
              class="filter-date-item-check"
              src="@/app/assets/images/check.svg"
            />
            <div>{{ $t('filters.alertTypeFilter.' + filter.name) }}</div>
          </c-dropdown-item>
        </c-dropdown>
      </div>
      <div class="filter-row-button" v-if="enableFilters.length!==0 && !hideApplyButton">
        <c-button
          :disabled="disabled || disableApplyButton"
          :class="{'hidden' : hideApplyButton}"
          variant="primary"
          class="filter-apply-button"
          @click="applyFilter"
          :id="getElementId('filter-button-apply')"
        >
          {{ $t('filters.dateFilter.applyFilters') }}
        </c-button>

        <c-link
          class="filter-clear-button"
          :class="{'disabled': disabled, 'hidden' : hideApplyButton}"
          :disabled="disabled"
          @click="clearFilter"
          :id="getElementId('filter-button-clear')"
        >
          {{ $t('filters.dateFilter.clearFilters') }}
        </c-link>
      </div>
      <slot name="dismiss_all"></slot>
    </div>
  </div>
</template>
<script>

import Formatter from "@/app/utils/common/functions/formatter";
import {DepositSubType} from "@/api/paymentApi";
import {DateFilters, PendoPrefixes} from "@/app/utils/common/constants";
import {
  getDatePeriodFromFilter,
  getDateTimeFromMillis,
  getDateTimeFromObject,
  getTimezoneName
} from "@/app/utils/common/functions/dateUtils";
import {isNullOrEmpty} from "@/app/utils/common/functions/functions";
import {
  AmountFilterOption,
  DefaultAlertTypeFilterOption, DefaultRefundTypeFilterOption,
  DefaultStatusFilterOption,
  DefaultTypeFilterOption, FilterEntity,
  PaymentMethodFilterOption
} from "@/app/utils/common/tableFilterOptions";
import AmountFilter from "@/components/common/AmountFilter.vue";
import MultiSelectDropdown from "@/components/common/MultiSelectDropdown.vue";

export default {
  name: "TableFilterOption",
  components: { MultiSelectDropdown, AmountFilter },
  props: {
    tableLabel: {
      type: String,
      default: "",
      required: false,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    currentTab: {
      type: String,
      required: false,
      default: "ALL",
    },
    dateFilterDuration: {
      type: Number,
      required: false,
      default: 3,
    },
    statusFilterOption: {
      type: Array,
      required: false,
      default: () => DefaultStatusFilterOption
    },
    typeFilterOption: {
      type: Array,
      required: false,
      default: () => DefaultTypeFilterOption
    },
    alertTypeFilterOption: {
      type: Array,
      required: false,
      default: () => DefaultAlertTypeFilterOption
    },
    enableFilters: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data: () => {
    return {
      hideApplyButton: true,
      disableApplyButton: false,
      dateFilter: {
        startDate: undefined,
        endDate: undefined,
      },
      currentDateFilter: "",
      defaultDateFilter: "",
      currentAmountFilter: "equals",
      currentDepositAmountFilter: "equals",
      currentStatusFilter: [],
      currentTypeFilter: [],
      currentDepositSubType: [],
      currentPaymentMethodFilter: [],
      currentAmountInput1: 0,
      currentAmountInput2: 0,
      currentDepositAmountInput1: 0,
      currentDepositAmountInput2: 0,
      dateRange: {
        start: null,
        end: null,
      },
      paymentMethodFilterOption: PaymentMethodFilterOption,
      amountFilterOption: AmountFilterOption,
      depositAmountFilterOption: AmountFilterOption,
      currentAmountFilterValue: {
        gt: null,
        gte: null,
        lt: null,
        lte: null,
      },
      currentDepositAmountFilterValue: {
        gt: null,
        gte: null,
        lt: null,
        lte: null,
      },
      currentAlertTypeFilter: 'all',
      filters: {
        dateFilterValue: {
          startDate: undefined,
          endDate: undefined,
        },
        dateRange: {
          start: null,
          end: null,
        },
        dateFilter: '',
        statusFilter: [],
        typeFilter: [],
        depositSubType: [],
        paymentMethodFilter: [],
        amountFilter: {
          gt: null,
          gte: null,
          lt: null,
          lte: null,
        },
        depositAmountFilter: {
          gt: null,
          gte: null,
          lt: null,
          lte: null,
        },
        alertTypeFilter: 'all',
        currentTab: 'ALL',
        applyFilterFlag: false,
      },
    }
  },
  computed: {
    FilterEntity() {
      return FilterEntity
    },
    getTimezoneName,
    getValueForDatePicker() {
      const start = this.currentDateFilter === DateFilters.CUSTOM ? this.dateRange.start : this.dateFilter.startDate?.getTime();
      const end = this.currentDateFilter === DateFilters.CUSTOM ? this.dateRange.end : this.dateFilter.endDate?.getTime();
      if (isNullOrEmpty(start) || isNullOrEmpty(end)) {
        return undefined;
      }
      return {end: end, start: start};
    },
    customDateFilterTitle() {
      const dates = this.getValueForDatePicker;
      let prefix = this.$t(`filters.dateFilter.from`);
      let suffix = this.$t(`filters.dateFilter.to`);
      if (isNullOrEmpty(dates) || Object.values(dates).every((value) => value === 0)) {
        return prefix + ' - ' + suffix
      }
      prefix = this.formatter({type: "fromMillisDateFilter", data: dates.start})
      suffix = this.formatter({type: "fromMillisDateFilter", data: dates.end})
      if (prefix === suffix) {
        return prefix
      }
      return prefix + ' - ' + suffix
    },
    dateFilterOption() {
      let durations = [
        {
          name: DateFilters.TODAY,
        },
        {
          name: DateFilters.ONE_WEEK,
        },
        {
          name: DateFilters.MONTH_TO_DATE,
        },
      ];

      if(['dashboard', 'deposits', 'disputes'].includes(this.$route.name)) durations.splice(0,1);

      if (this.dateFilterDuration === 1) {
        durations.push({name: DateFilters.ONE_MONTH});
      }
      if (this.dateFilterDuration >=2) {
        durations.push({name: DateFilters.PREVIOUS_MONTH}, {name: DateFilters.QUARTER_TO_DATE});
      }

      if (['transactions', 'authorizations'].includes(this.$route.name)) durations.push({name: DateFilters.THREE_MONTH})

      if (this.dateFilterDuration >= 12) {
        durations.push({name: DateFilters.YEAR_TO_DATE});
        durations.push({name: DateFilters.ONE_YEAR});
      }
      durations.push({name: DateFilters.CUSTOM});
      return durations
    },
    changeFilterBasedOnTabAndTable() {
      this.manageTab(this.currentTab);
      this.manageType(this.tableLabel);
      this.storeCurrentTabValue();
      let tempTypeFilter = [...this.currentTypeFilter];
      const typesToRemove = ['CHARGEBACK_FEE', 'RESERVE_ADJUSTMENT_DEBIT', 'RESERVE_ADJUSTMENT_CREDIT', 'RESERVE_TRANSFER'];
      tempTypeFilter = tempTypeFilter.filter(type => !typesToRemove.includes(type));
      return [this.currentStatusFilter, tempTypeFilter];
    },
    isStatusFilterDisabled() {
      return this.disabled || ['SUCCESS', 'FAILED', 'REFUND', 'OPEN', 'RESPONDED', 'WON', 'LOST'].includes(this.currentTab);
    },
    isTypeFilterDisabled() {
      const isPaymentsFilter = this.tableLabel === 'PaymentsAndRefunds' && this.currentTab !== 'ALL';
      const isRefundsFilter = this.tableLabel === 'Refunds' && this.currentTab === 'REFUND';
      const isDisputesFilter = this.tableLabel === 'Disputes' && this.currentTab === 'INQUIRY';
      return this.disabled || isPaymentsFilter || isDisputesFilter || isRefundsFilter;
    },
  },
  mounted() {
    this.getDateFilterFromStorage()
  },
  activated() {
    this.getDateFilterFromStorage()
  },
  methods: {
    formatter: Formatter.format,
    setHourOfTheDay: Formatter.setHourOfTheDay,
    getDateFilterFromStorage: function () {
      let dateFilter = window.cbStorage.getItem("dateFilter");
      if (!isNullOrEmpty(dateFilter)) {
        this.defaultDateFilter = dateFilter
        this.filters.dateFilter = dateFilter
        window.cbStorage.removeItem('dateFilter');
        this.switchDateFilter(dateFilter);
      }
    },
    switchDateFilter: function (newFilter) {
      if (this.currentDateFilter === newFilter) {
        return;
      }
      if (newFilter === "custom") {
        // To address the issue of the c-date-time-picker not resetting when receiving null or undefined,
        // we pass the value as 0 to dateRange when the filter is custom,
        // Additionally, we prevent users from applying the filters when dateRange start and end are 0.
        if (this.dateRange.start === null || this.dateRange.end === null) {
          this.dateRange.start = 0;
          this.dateRange.end = 0;
        }
        this.dateFilter.startDate = this.setHourOfTheDay(getDateTimeFromMillis(this.dateRange.start), 0);
        this.dateFilter.endDate = this.setHourOfTheDay(getDateTimeFromMillis(this.dateRange.end), 24);
      } else {
        ({ startDate: this.dateFilter.startDate, endDate: this.dateFilter.endDate } = getDatePeriodFromFilter(newFilter));
      }

      this.currentDateFilter = newFilter;
      this.checkDefaultFilters();
      this.checkPreviousFilterState()
    },
    manageAlertTypeFilter: function (newFilter) {
      this.currentAlertTypeFilter = newFilter;
      this.checkDefaultFilters();
      this.checkPreviousFilterState();
    },
    manageFilter: function (filterList, filter) {
      if (filterList.includes(filter)) {
        filterList.splice(filterList.indexOf(filter), 1);
      } else {
        filterList.push(filter);
      }
      this.checkForTransactionTypesFilter()
      this.checkForAppliedDepositFilters()
      this.checkDefaultFilters()
      this.checkPreviousFilterState()
    },
    manageTab : function (filter) {
      if (this.currentTab !== "ALL" && this.currentTab !== "REFUND" && this.currentTab !== "INQUIRY") {
        this.currentStatusFilter.length = 0;
        this.currentStatusFilter.push(filter);
      }

      if (this.filters.currentTab !== "REFUND" && this.currentTab === "REFUND" && this.tableLabel === "Refunds") {
        this.currentStatusFilter.length = 0;
        this.currentStatusFilter = ["SUCCESS", "FAILED"];
      }

      if ((this.filters.currentTab !== "ALL" && this.currentTab === "ALL" && this.tableLabel !== "Disputes")
          || (["OPEN", "LOST", "RESPONDED", "WON"].includes(this.filters.currentTab)
              && ["ALL", "INQUIRY"].includes(this.currentTab))) {
        this.currentStatusFilter.length = 0;
        this.filters.statusFilter = [];
      }
    },
    manageType: function () {
      if (!["ALL", "REFUND"].includes(this.currentTab) && this.tableLabel === 'PaymentsAndRefunds') {
        this.currentTypeFilter.length = 0;
        this.currentTypeFilter.push('DEBIT');
      }

      if(this.currentTab === "REFUND" && this.tableLabel === "Refunds") {
        this.currentTypeFilter.length = 0;
        this.currentTypeFilter = DefaultRefundTypeFilterOption;
      }

      if(this.currentTab === "INQUIRY" && this.tableLabel === "Disputes") {
        this.currentTypeFilter.length = 0;
        this.currentTypeFilter.push("INQUIRY");
      }

      if ((this.filters.currentTab !== "ALL" && this.currentTab === "ALL" && this.tableLabel === 'PaymentsAndRefunds')
          || (this.filters.currentTab === "INQUIRY" && this.currentTab !== "INQUIRY" && this.tableLabel === "Disputes")) {
        this.currentTypeFilter.length = 0;
        this.filters.typeFilter = [];
      }
    },
    storeCurrentTabValue: function () {
      this.filters.currentTab = this.currentTab;
      this.checkDefaultFilters()
      this.checkPreviousFilterState()
    },
    updateFilter: function () {
      this.filters.dateFilterValue = {...this.dateFilter}
      this.filters.dateFilter = this.currentDateFilter
      this.filters.dateRange = this.dateRange
      this.filters.statusFilter = [...this.currentStatusFilter]
      this.filters.typeFilter = [...this.currentTypeFilter]
      this.filters.depositSubType = [...this.currentDepositSubType]
      this.filters.paymentMethodFilter = [...this.currentPaymentMethodFilter]
      this.filters.amountFilter = {...this.currentAmountFilterValue}
      this.filters.depositAmountFilter = {...this.currentDepositAmountFilterValue}
      this.filters.alertTypeFilter = this.currentAlertTypeFilter
      this.checkDefaultFilters()
      this.checkPreviousFilterState()
      this.filters.applyFilterFlag = this.disableApplyButton && !this.hideApplyButton
    },
    applyFilter: function () {
      this.updateFilter();
      this.$emit('childApplyFilter', this.filters);
    },
    update: function (dateRange) {
      // The c-date-time-picker emits an update event with whatever value props is passed on to it whenever the date
      // range selection changes, but it emits the final value only after the user clicks the apply button.
      // So, validation is necessary before update if coming dateRange is actually the new value
      if (dateRange !== undefined && !((this.getValueForDatePicker?.start === dateRange.start)
          && this.getValueForDatePicker?.end === dateRange.end)) {
        this.switchDateFilter(DateFilters.CUSTOM)
        this.dateRange = dateRange;
        this.dateFilter.startDate = this.setHourOfTheDay(getDateTimeFromMillis(dateRange.start), 0);
        this.dateFilter.endDate = this.setHourOfTheDay(getDateTimeFromMillis(dateRange.end), 24);
        this.checkPreviousFilterState();
      }
    },
    clearFilter: function () {
      this.update({
        start: 0,
        end: 0
      })
      this.switchDateFilter(this.defaultDateFilter)
      this.currentDateFilter = this.defaultDateFilter
      this.currentAmountFilter = "equals"
      this.currentDepositAmountFilter = "equals"
      this.currentAmountInput1 = 0
      this.currentAmountInput2 = 0
      this.currentDepositAmountInput1 = 0
      this.currentDepositAmountInput2 = 0
      this.currentDepositSubType = []
      this.applyAmountFilter("reset", this.currentAmountInput1,
          this.currentAmountInput2, this.currentAmountFilterValue)
      this.applyAmountFilter("reset", this.currentDepositAmountInput1,
          this.currentDepositAmountInput2, this.currentDepositAmountFilterValue)
      this.currentStatusFilter = !['INQUIRY', 'ALL'].includes(this.currentTab) ? this.currentStatusFilter : []
      this.currentTypeFilter = ((this.tableLabel === 'Disputes' && this.currentTab === "INQUIRY")
          || (this.tableLabel === 'PaymentsAndRefunds' && this.currentTab !== "ALL")
          || (this.tableLabel === 'Refunds' && this.currentTab === 'REFUND')) ? this.currentTypeFilter : [];
      this.currentPaymentMethodFilter = []
      this.hideApplyButton = true
      this.currentAlertTypeFilter = "all";
      this.updateFilter()
      this.$emit('childClearFilter', this.filters);
    },
    dateSelectorLimit: function (months) {
      let endDateSelectorLimit = new Date(getDateTimeFromObject()
          .plus({days: (30 * months)}).toISODate())
      if (months % 12 === 0) {
        endDateSelectorLimit = new Date(getDateTimeFromObject()
            .plus({years: (months / 12)}).toISODate())
      }
      return [new Date(getDateTimeFromObject().toISODate()),
        endDateSelectorLimit]
    },
    applyAmountFilter: function (currentAmountFilter, amountInput1, amountInput2, filterValue) {
      let input1 = amountInput1 === 0 ? null : (amountInput1 * 100).toFixed();
      let input2 = amountInput2 === 0 ? null : (amountInput2 * 100).toFixed();
      switch (currentAmountFilter) {
        case "equals":
          filterValue.gt = null;
          filterValue.gte = input1;
          filterValue.lt = null;
          filterValue.lte = input1;
          break;
        case "between":
          filterValue.gt = null;
          filterValue.gte = input1;
          filterValue.lt = null;
          filterValue.lte = input2;
          break;
        case "greater_than":
          filterValue.gt = input1;
          filterValue.gte = null;
          filterValue.lt = null;
          filterValue.lte = null;
          break;
        case "less_than":
          filterValue.gt = null;
          filterValue.gte = null;
          filterValue.lt = input1;
          filterValue.lte = null;
          break;
        default:
          filterValue.gt = null;
          filterValue.gte = null;
          filterValue.lt = null;
          filterValue.lte = null;
          break;
      }
    },
    checkDefaultFilters: function () {
      let checkCurrentAndPrevFilterIsToday = this.currentDateFilter === this.defaultDateFilter && this.filters.dateFilter === this.defaultDateFilter;
      let checkCurrentAndPrevStatusIsEmpty = (this.currentStatusFilter.length === 0 &&
          this.filters.statusFilter.length === 0);
      let checkCurrentAndPrevPaymentMethodIsEmpty = (this.currentPaymentMethodFilter.length === 0 &&
          this.filters.paymentMethodFilter.length === 0);
      let checkCurrentAndPrevTypeIsEmpty = (this.currentTypeFilter.length === 0 &&
          this.filters.typeFilter.length === 0);
      let checkDefaultAmountFilter = this.currentAmountInput1 === 0 &&
          this.currentAmountInput2 === 0;
      let checkDefaultDepositAmountFilter = this.currentDepositAmountInput1 === 0 &&
          this.currentDepositAmountInput2 === 0;
      let checkTabSwitch = this.currentTab !== 'ALL' && this.currentTab !== 'INQUIRY';
      let checkPaymentTable = this.tableLabel === 'PaymentsAndRefunds' || this.tableLabel === 'Refunds';
      let checkDisputeTableWithInquiryTab = (this.currentTab === "INQUIRY" && this.tableLabel === "Disputes");
      let checkPaymentTableAndTabSwitch = checkPaymentTable && checkTabSwitch;
      let checkTabSwitchOrCurrentAndPrevStatusIsEmpty = (checkTabSwitch || checkCurrentAndPrevStatusIsEmpty);
      let checkRefundTableOrPaymentTableAndTabSwitchOrCurrentAndPrevTypeIsEmpty = (checkPaymentTableAndTabSwitch
          || checkDisputeTableWithInquiryTab || checkCurrentAndPrevTypeIsEmpty);
      let checkCurrentAndPrevAlertTypeIsAll = this.currentAlertTypeFilter === 'all' && this.currentAlertTypeFilter.toString() === this.filters.alertTypeFilter.toString();

      if (checkCurrentAndPrevFilterIsToday && checkCurrentAndPrevPaymentMethodIsEmpty
          && checkTabSwitchOrCurrentAndPrevStatusIsEmpty && checkDefaultDepositAmountFilter
          && checkRefundTableOrPaymentTableAndTabSwitchOrCurrentAndPrevTypeIsEmpty && checkDefaultAmountFilter
          && checkCurrentAndPrevAlertTypeIsAll) {
        this.hideApplyButton = true;
        return;
      }
      this.hideApplyButton = false;
    },
    checkPreviousFilterState: function () {
      let checkCurrentAndPrevDateFilter = this.currentDateFilter === this.filters.dateFilter;
      let checkDateRangeIsSame = Object.values(this.dateRange).every((value) => value === 0);
      let checkCurrentDateFilterIsCustom = this.currentDateFilter === DateFilters.CUSTOM;
      let checkCurrentAndPrevPaymentMethod = this.currentPaymentMethodFilter.toString() ===
          this.filters.paymentMethodFilter.toString();
      let checkCurrentAndPrevStatus = this.currentStatusFilter.toString() === this.filters.statusFilter.toString();
      let checkCurrentAndPrevType = this.currentTypeFilter.toString() === this.filters.typeFilter.toString();
      /* Check checkCurrentAndPrevDateRange should only be considered when date filter is custom
       * Verifying this.dateRange value is different from previously applied dateRange
       * And dateRange start and end value should be different*/
      let checkCurrentAndPrevDateRange = !checkCurrentDateFilterIsCustom
          || ((JSON.stringify(this.dateRange) === JSON.stringify(this.filters.dateRange))
              || (Object.values(this.dateRange).every((value) => value === 0)));
      let checkCurrentAndPrevAmount = JSON.stringify(this.currentAmountFilterValue) ===
          JSON.stringify(this.filters.amountFilter);
      let checkCurrentAndPrevDepositAmount = JSON.stringify(this.currentDepositAmountFilterValue) ===
          JSON.stringify(this.filters.depositAmountFilter);
      let checkTabSwitch = this.currentTab !== 'ALL' && this.currentTab !== 'INQUIRY';
      let checkPaymentTable = this.tableLabel === 'PaymentsAndRefunds' || this.tableLabel === 'Refunds';
      let checkTabSwitchOrCurrentAndPrevStatus = (checkTabSwitch || checkCurrentAndPrevStatus);
      let checkTabSwitchOrCurrentAndPrevType = ((checkTabSwitch && checkPaymentTable)
          || (this.currentTab === 'INQUIRY' && this.tableLabel === 'Disputes') || checkCurrentAndPrevType);
      let checkChangeInDateFilter = (checkCurrentAndPrevDateFilter ||
          (checkCurrentDateFilterIsCustom && checkDateRangeIsSame))
      let checkCurrentAndPrevAlertType = this.currentAlertTypeFilter.toString() === this.filters.alertTypeFilter.toString();
      if (checkChangeInDateFilter && checkCurrentAndPrevPaymentMethod &&
          checkTabSwitchOrCurrentAndPrevStatus && checkTabSwitchOrCurrentAndPrevType &&
          checkCurrentAndPrevAmount && checkCurrentAndPrevDepositAmount && checkCurrentAndPrevDateRange
          && checkCurrentAndPrevAlertType
      ){
        this.disableApplyButton = true;
      } else {
        this.disableApplyButton = false;
      }
    },
    // DepostiSubType is assigned as SETTLEMENT_MERCHANT only when any of the deposit related filters are applied.
    // i.e Deposit status, Deposit type and Deposit amount
    checkForAppliedDepositFilters: function (amount1 = 0, amount2 = 0) {
      if (this.tableLabel === "Deposits") {
        if (this.currentStatusFilter.length !== 0 || (amount1 !== 0 || amount2 !== 0)
            || this.currentTypeFilter.length !== 0) {
          this.currentDepositSubType = [DepositSubType.MERCHANT];
        } else {
          this.currentDepositSubType = [];
        }
      }
    },
    checkForTransactionTypesFilter() {
      const dependencies = {
        'CHARGEBACK_DEBIT': ['CHARGEBACK_FEE', 'RESERVE_ADJUSTMENT_DEBIT'],
        'CHARGEBACK_CREDIT': ['RESERVE_ADJUSTMENT_CREDIT'],
        'RESERVE': ['RESERVE_TRANSFER']
      };

      Object.keys(dependencies).forEach(dependent => {
        dependencies[dependent].forEach(dependency => {
          if (this.currentTypeFilter.includes(dependent) && !this.currentTypeFilter.includes(dependency)) {
            this.currentTypeFilter.push(dependency);
          }
          if (!this.currentTypeFilter.includes(dependent) && this.currentTypeFilter.includes(dependency)) {
            this.currentTypeFilter.splice(this.currentTypeFilter.indexOf(dependency), 1);
          }
        });
      });
    },
    getElementId(id) {
      let currentId = PendoPrefixes.CBpay + this.$route.name;
      if(this.currentTab === 'TODO' || this.currentTab=== 'EVENTS')
        currentId = currentId + "-" + this.currentTab.toLowerCase() + "-tab";
      if(id === "filter-date") {
        let tableLabel = "";
        switch (this.$route.name) {
          case "dashboard":
            tableLabel = "insights_period";
            break;
          case "transactions":
            tableLabel = "transaction_period";
            break;
          case "refunds":
            tableLabel = "refund_period";
            break;
          case "deposits":
            tableLabel = "deposit_period";
            break;
          case "disputes":
            tableLabel = "initiated_period";
            break;
          case "notifications":
            tableLabel = "events_period";
            break;
        }
        currentId = currentId + "-filter-" +  tableLabel;
        id = "";
      }
      return id === "" ? currentId : currentId + "-" + id.toLowerCase();
    }
  },
}
</script>
<style lang="scss">
.table-header-container {
  margin-bottom: 14px;

  & .filter-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & .filter-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    & .filter-row-button {
      display: flex;
      flex-direction: row;
      min-width: 250px;
    }

    & .filter {
      z-index: 99;
      margin-right: 12px;
      display: flex;
    }

    & .filter:last-child {
      margin-right: 12px;
    }

    & .sub-filter {
      z-index: 99;
      display: flex;
      height: 30px;
      width: 100%;
      min-width: 200px;

      & .c-dropdown__trigger {
        width: 100%;
      }

      & .filter-button {
        width: 100%;

        & .filter-name {
          display: flex;
          flex: 1;
          text-align: left;
        }
      }
    }

    & .filter-amount-item-container:hover {
      background: none;
    }

    & .filter-amount-item-container {
      display: flex;
    }

    & .filter-hide {
      display: none;
    }

    & .filter-apply-button {
      height: 30px;
      line-height: 18px;
      margin-right: 20px;
      border-radius: 6px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      padding: 0;
      visibility: visible;
    }

    & .filter-clear-button {
      height: 32px;
      line-height: 18px;
      margin-right: 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: $neutral_800;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      visibility: visible;

      & .filter-icon {
        margin-right: 10px;
      }
    }

    & .hidden {
      visibility: hidden;
    }

    & .disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    & .filter-button {
      background: #FFFFFF;
      border: 1px solid $neutral_200;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
      border-radius: 6px;
      height: 32px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      padding: 0;

      & .c-button__label {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding-left: 12px;
        padding-right: 12px;
      }

      & .filter-name-value-container {
        display: flex;
      }

      & .filter-name {
        color: $neutral_800;
        font-weight: $weight_normal;
      }

      & .filter-value {
        margin-left: 5px;
        color: $primary_600;
        font-weight: 500;

        & .filter-value-container {
          display: flex;
          flex-direction: row;
        }

        .filter-value-item ~ .filter-value-item::before {
          content: ", ";
        }

        .filter-value-item:last-child {
          margin-right: 5px;
        }
      }

      & .filter-icon {
        margin-left: 8px;
        transform: rotate(0deg);
        transition: transform 1s linear;
      }

      & .filter-icon .open {
        transform: rotate(180deg);
        transition: transform 1s linear;
      }
    }

    & .filter-date-item-container {
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: $neutral_800;
      display: flex;
      flex-direction: row;

      & .filter-date-item-check {
        margin: 0 10px;
        visibility: hidden;
      }

      & .filter-date-item-active {
        visibility: visible;
      }
    }
  }

  & .tabs {
    display: flex;

    > div:first-child {
      position: fixed;
      width: 100%;
      background-color: $color_white;
      z-index: 99;
      padding-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  & .c-dropdown__container{
    margin-top: 0 !important;
  }
}
</style>