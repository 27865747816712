<template>
  <div class="dispute-detail-container">
    <SidebarNav @update:sidebarNavCollapsed="sidebarNavCollapsed = $event" />
    <SearchBar :sidebar-nav-collapsed="sidebarNavCollapsed" />
    <div
      class="dispute-detail-content"
      :class="{['dispute-detail-content-sidebar-collapse']: sidebarNavCollapsed}"
    >
      <div class="dispute-detail-sub-content">
        <div class="options-bar" v-if="dispute">
          <div class="back-to-disputes" @click="navigateTo('disputes')" :id="getElementId('go_back')">
            <img src="@/app/assets/images/back.svg" alt="Back to disputes icon" />
            <span>{{ $t('disputes.actions.backToDisputes') }}</span>
          </div>
          <div class="respond-buttons" v-if="dispute.status === 'PENDING' || dispute.status === 'INQUIRY'">
            <c-button
              variant="secondary"
              size="large"
              :disabled="acceptLoading"
              v-if="dispute.status === 'PENDING' && dispute.type !== 'INQUIRY'"
              @click="promptAcceptConfirmation"
            >
              {{ $t('disputes.actions.acceptDispute') }}
            </c-button>
            <c-button
              variant="primary"
              size="large"
              @click="navigateToResponsePage"
              :id="getElementId('respond_to_dispute')"
            >
              {{ $t('disputes.actions.respondToDispute') }}
            </c-button>
          </div>
        </div>
        <c-modal
          v-if="dispute"
          :control-variable="showAcceptPopup"
          :primary-action="$t('global.action.confirm')"
          :secondary-action="$t('global.action.cancel')"
          :heading="$t('disputes.confirmAcceptDispute')"
          variant="danger"
          @primary="acceptDispute"
          @close="() => showAcceptPopup = false"
          :id="getElementId('accept_pop_up')"
          :description="$t('disputes.confirmAcceptDisputeWarning', {amount: getDisputeAmount()})"
        />
        <div class="loading-message" v-if="!dispute">
          Fetching dispute...
        </div>
        <div class="main-layout-container" v-else>
          <div class="cl-dock__main">
            <div class="layout-container">
              <div class="cards">
                <div class="card-container first-card">
                  <div class="card">
                    <DisputeDetail :dispute="dispute" />
                  </div>
                </div>
                <div class="card-container" id="overview">
                  <div class="card">
                    <DisputeOverview :dispute="dispute" :transaction="transaction" />
                  </div>
                </div>

                <div class="card-container" v-if="evidence" id="evidence">
                  <div class="card">
                    <DisputeEvidenceInfo :evidence-info="evidence" />
                  </div>
                </div>

                <div class="card-container" v-if="transaction" id="transaction">
                  <div class="card">
                    <DisputeTransactionInfo :dispute="dispute" :transaction="transaction" />
                  </div>
                </div>

                <div class="card-container" id="payment">
                  <div class="card">
                    <PaymentInstrument :payment-instrument="dispute.payment_instrument" />
                  </div>
                </div>

                <div class="card-container" v-if="dispute && transaction" id="audit-logs">
                  <div class="card">
                    <DisputeAuditLogs :dispute="dispute" :transaction="transaction" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cl-dock__sidebar">
            <TableOfContents :sections="sections" />
          </div>
        </div>
        <PortalFooter />
      </div>
    </div>
  </div>
</template>

<script>
import SidebarNav from "@/components/SidebarNav";
import {portalCheckMixin} from "@/mixin/portalPageCheck";
import {goBack, navigateTo} from "@/router";
import DisputeDetail from "@/components/disputes/DisputeDetail";
import disputes from "@/api/disputes";
import {mapGetters} from "vuex";
import DisputeOverview from "@/components/disputes/DisputeOverview";
import DisputeTransactionInfo from "@/components/disputes/DisputeTransactionInfo";
import transactions from "@/api/transactions";
import DisputeEvidenceInfo from "@/components/disputes/DisputeEvidenceInfo";
import DisputeAuditLogs from "@/components/disputes/DisputeAuditLogs";
import Formatter from "@/app/utils/common/functions/formatter";
import SearchBar from "@/components/SearchBar";
import PortalFooter from "@/components/portal/PortalFooter.vue";
import {PendoPrefixes} from "@/app/utils/common/constants";
import TableOfContents from "@/components/common/TableOfContents.vue";
import locales from "@/i18n/locales";
import PaymentInstrument from "@/components/PaymentInstrument.vue";
import {RouteConstants} from "@/router/routeConstants";

export default {
  name: "DisputeDetailsView",
  components: {
    PaymentInstrument,
    TableOfContents,
    SearchBar,
    DisputeEvidenceInfo,
    DisputeDetail, SidebarNav, DisputeOverview, DisputeTransactionInfo,
    DisputeAuditLogs, PortalFooter
  },
  mixins: [portalCheckMixin],
  data: function () {
    return {
      sidebarNavCollapsed: false,
      dispute: null,
      evidence: null,
      transaction: null,
      showAcceptPopup: false,
      acceptLoading: false,
      sections: [],
      titles: [
        "profile",
        "address",
      ],
    }
  },
  computed: {
    ...mapGetters('app', ['getMerchantId']),
    loading() {
      return this.dispute === null
    },
  },
  mounted() {
    this.portalChecks().then(() => {
      this.initialize();
    });
  },
  methods: {
    async initialize() {
      if (this.$route.params.data === undefined || this.$route.params.data === null) {
        this.dispute = await disputes.fetchDisputeDetails(this.getMerchantId, this.$route.params.disputeId)
      } else {
        this.dispute = this.$route.params.data
      }

      if (this.dispute.evidence_info) {
        this.evidence = await disputes.getEvidencesForDispute(this.getMerchantId, this.dispute.id);
      }
      this.transaction = await transactions.fetchConsumerTransaction(this.getMerchantId,
          this.dispute.consumer.id,
          this.dispute.source_transaction_id);

      this.sections = [
        {id: 'overview', title: locales.en.tableOfContent.overview, show: true},
        {id: 'evidence', title: locales.en.tableOfContent.disputes.evidence, show: this.evidence != null},
        {id: 'transaction', title: locales.en.tableOfContent.disputes.transaction, show: this.transaction != null},
        {id: 'payment', title: locales.en.tableOfContent.disputes.payment_instrument, show: true},
        {
          id: 'audit-logs',
          title: locales.en.tableOfContent.disputes.auditLogs,
          show: (this.transaction != null && this.dispute != null)
        },
      ]
    },
    navigateTo: function (route) {
      navigateTo(route, false, this.$route.query);
    },
    navigateBack: function () {
      goBack()
    },
    promptAcceptConfirmation: function () {
      this.showAcceptPopup = true;
    },
    navigateToResponsePage: function () {
      navigateTo(RouteConstants.DISPUTE_RESPONSE, true, this.$route.query)
    },
    getDisputeAmount: function () {
      return Formatter.format({
        type: 'currency', data: this.dispute.amount, format: "",
        currencyCode: this.dispute.currency,
      }) + this.dispute.currency;
    },
    acceptDispute: async function () {
      this.showAcceptPopup = false
      this.acceptLoading = true
      try {
        this.dispute = await disputes.acceptTheDispute(this.getMerchantId, this.dispute.id)
        this.$success(this.$t('disputes.markedAsLost'))
      } catch (e) {
        this.$danger(this.$t('disputes.markAsLostFailed'))
      }
    },
    getElementId(id) {
      return PendoPrefixes.CBpay + this.$route.name + '-' + id;
    }
  },
}
</script>

<style lang="scss">
.dispute-detail-container {
  background: $neutral_50;
  height: 100%;
  overflow: scroll;


  & .loading-message {
    color: $primary_text_dark;
    margin-top: 52px;
  }

  & .main-layout-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    & .cl-dock__main {
      width: calc(75% - 8px);
      margin-right: 32px;

      @media screen and (max-width: 700px) {
        width: 100%;
      }
    }

    & .cl-dock__sidebar {
      top: 50px;
      position: sticky;
      border-radius: 8px;
      width: calc(25% - 12px);
      align-self: flex-start;

      @media screen and (max-width: 700px) {
        display: none;
        width: 0;
      }
    }

    & .layout-container {
      width: 100%;
      overflow: auto;

      & .cards {
        width: 100%;

        & .card-container {
          scroll-margin-top: 70px;
          background-color: #FFFFFF;
          padding: 24px;
          border-radius: 6px;
          margin-top: 24px;

          & .card {
            width: 100%;
          }
        }

        & .first-card {
          margin-top: 0;
        }
      }

    }
  }

  & .dispute-detail-content {
    left: 256px;
    top: 0;
    position: relative;
    width: calc(100% - 256px);
    padding: 24px 32px;
    transition: all 0.2s ease-in;
    justify-content: center;
    display: flex;

    & .dispute-detail-sub-content {
      max-width: 1360px;
      width: 100%;
    }

    & .options-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 28px;

      & .back-to-disputes {
        cursor: pointer;
        color: $neutral_600;
        font-weight: $weight_medium;

        & span {
          margin-left: 8px;
        }
      }

      & .respond-buttons {
        display: flex;
        align-items: center;
        z-index: 99;

        & button {
          margin-left: 16px;
          margin-bottom: 0;

          & .c-button__label {
            padding: 0;
          }
        }
      }
    }
  }
  & .dispute-detail-content-sidebar-collapse {
    left: 86px;
    width: calc(100% - 86px);
  }
}
</style>