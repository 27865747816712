<template>
  <div class="report-filter-header-container">
    <div class="report-filter-container">
      <div class="filter-row">
        <!-- Month Filter 👇-->
        <c-dropdown :class="{'disabled': disabled}" :disabled="disabled" class="filter">
          <c-button slot="trigger" class="filter-button">
            <div class="filter-name-value-container">
              <div class="filter-name">
                {{ $t('filters.monthFilter.title') }}
              </div>
              <div class="filter-value">
                <div class="filter-value-item">
                  {{ currentMonthDisplay }}
                </div>
              </div>
            </div>
            <img alt="dropdown" class="filter-icon" src="@/app/assets/images/dropdown.svg" />
          </c-button>

          <c-dropdown-item
            v-for="filter in monthFilterOption"
            :key="filter.key"
            :option="filter.key"
            :disabled="filter.isDisabled"
            class="filter-date-item-container"
            @click="() => manageMonthYearFilter(filter.key)"
          >
            <img
              :class="{'filter-date-item-active': filter.key === monthYearFilter.current}"
              alt="check"
              class="filter-date-item-check"
              src="@/app/assets/images/check.svg"
            />
            <ToolTip
              v-if="filter.isDisabled && showTooltipForLatestMonth(filter)"
              placement="right"
              :content="tooltipContentForLatestMonth(filter)"
            >
              <div>{{ filter.shortMonth }} {{ filter.year }}</div>
            </ToolTip>
            <div v-else>
              <ToolTip 
                v-if="filter.isDisabled && !showTooltipForLatestMonth(filter)" 
                placement="right" 
                :content="$t('reports.reportNotAvailable')"
              >
                {{ filter.shortMonth }} {{ filter.year }}
              </ToolTip>
              <div v-else>
                {{ filter.shortMonth }} {{ filter.year }}
              </div>
            </div>
          </c-dropdown-item>
        </c-dropdown>
      </div>
      <div class="filter-row-button" v-if="!hideApplyButton">
        <c-button
          :disabled="disabled || disableApplyButton"
          :class="{'hidden': hideApplyButton}"
          variant="primary"
          class="filter-apply-button"
          @click="applyFilter"
        >
          {{ $t('filters.dateFilter.applyFilters') }}
        </c-button>

        <c-link
          class="filter-clear-button"
          :class="{'disabled': disabled, 'hidden': hideApplyButton}"
          :disabled="disabled"
          @click="clearFilter"
        >
          {{ $t('filters.dateFilter.clearFilters') }}
        </c-link>
      </div>
      <slot name="dismiss_all"></slot>
    </div>
  </div>
</template>

<script>
import Formatter from "@/app/utils/common/functions/formatter";
import { mapGetters } from "vuex";
import {
  getAbbreviatedMonth,
  getMonthYearInfosFromMonthOffset,
  setToPreviousMonthLastDay,
  isGivenDateBetweenFirstAndFifth
} from "@/app/utils/common/functions/dateUtils"
import locales from "@/i18n/locales";
import ToolTip from "@/components/common/ToolTip.vue";

export default {
  name: "ReportTableFilterOption",
  components: {ToolTip},
  props: {
    tableLabel: {
      type: String,
      default: "",
      required: false,
    },
    disabled: {
      type: Boolean,
      required: true,
    }
  },
  data: () => {
    return {
      hideApplyButton: true,
      disableApplyButton: false,
      currentPeriodFilter: 'MONTHLY',
      monthYearFilter: {
        previous: '',
        current: '',
        currentDisplay: ''
      },
      filters: {
        currentMonthFilter: ''
      },
    }
  },
  computed: {
    ...mapGetters('app', ['getMerchantCreatedAt']),
    monthFilterOption() {
      let months = [];
      let currentDate = new Date();
      let merchantCreatedAtDate = new Date(this.getMerchantCreatedAt);
      setToPreviousMonthLastDay(currentDate);

      //Scenario 1: If merchant created Date year and month is equal to current Date year and month.
      if (this.isDateEqualToMerchantCreatedAt(merchantCreatedAtDate, new Date())) {
        return months;
      }

      //Scenario 2: For immediate previous month, check if current month day is greater than 5.
      if(isGivenDateBetweenFirstAndFifth(new Date())) {
        months.push(this.buildMonthYearObjForReport(currentDate, true));
      } else {
        months.push(this.buildMonthYearObjForReport(currentDate, false));
      }
      setToPreviousMonthLastDay(currentDate);

      //Scenario 3: Iterate back from currentDate to merchant createdAt date and get all valid months..
      while(this.isDateGreaterThanMerchantCreatedAt(merchantCreatedAtDate, currentDate)) {
        months.push(this.buildMonthYearObjForReport(currentDate, false));
        setToPreviousMonthLastDay(currentDate);
      }
      return months;
    },
    currentMonthDisplay: function () {
      let monthYear = this.monthFilterOption.find(v => v.key === this.monthYearFilter.current);
      return monthYear && monthYear.shortMonth.slice(0, 3) + " " + monthYear.year;
    }
  },
  mounted() {
    this.setDefaultFilter();
  },
  activated() {
    this.setDefaultFilter();
  },
  methods: {
    formatter: Formatter.format,
    setHourOfTheDay: Formatter.setHourOfTheDay,
    setDefaultFilter: function () {
      let defaultMonth = isGivenDateBetweenFirstAndFifth(new Date()) ? 2 : 1;
      let defaultMonthYear = getMonthYearInfosFromMonthOffset(defaultMonth);
      let defaultValue = defaultMonthYear.shortMonth + "-" + defaultMonthYear.year;
      this.monthYearFilter.previous = this.monthYearFilter.current = defaultValue;
      this.disableApplyButton = false;
      this.hideApplyButton = true;
    },
    buildMonthYearObjForReport: function(reportDate, isDisabled) {
      let shortMonth = getAbbreviatedMonth(reportDate);
      let year = reportDate.getFullYear();
      let formattedMonthYear = shortMonth + "-" + year;
      return {shortMonth: shortMonth, year: year, key: formattedMonthYear, isDisabled: isDisabled};
    },
    manageMonthYearFilter: function (value) {
      this.monthYearFilter.current = value;
      this.checkDefaultFilters();
    },
    updateFilter: function () {
      this.filters.currentMonthFilter = this.monthYearFilter.previous = this.monthYearFilter.current;
      this.disableApplyButton = true;
    },
    applyFilter: function () {
      this.updateFilter();
      this.$emit('childApplyFilter', this.filters);
    },
    clearFilter: function () {
      this.setDefaultFilter();
      this.updateFilter();
      this.$emit('childClearFilter', this.filters);
    },
    checkDefaultFilters: function () {
      let checkMonthYearFilter = this.monthYearFilter.current === this.monthYearFilter.previous;
      if (checkMonthYearFilter) {
        this.hideApplyButton = true;
        return;
      }
      this.hideApplyButton = false;
      this.disableApplyButton = false;
    },
    isDateGreaterThanMerchantCreatedAt: function (merchantCreatedAt, date) {
      const merchantCreatedAtYear = merchantCreatedAt.getFullYear();
      const merchantCreatedAtMonth = merchantCreatedAt.getMonth();

      const dateYear = date.getFullYear();
      const dateMonth = date.getMonth();
      return dateYear > merchantCreatedAtYear
          || (dateYear === merchantCreatedAtYear && dateMonth > merchantCreatedAtMonth);
    },
    isDateEqualToMerchantCreatedAt: function (merchantCreatedAt, date) {
      const merchantCreatedAtYear = merchantCreatedAt.getFullYear();
      const merchantCreatedAtMonth = merchantCreatedAt.getMonth();

      const dateYear = date.getFullYear();
      const dateMonth = date.getMonth();
      return (dateYear === merchantCreatedAtYear && dateMonth === merchantCreatedAtMonth);
    },
    showTooltipForLatestMonth: function(filter) {
      let currentDate = new Date();
      setToPreviousMonthLastDay(currentDate);
      return filter.shortMonth === getAbbreviatedMonth(currentDate) && isGivenDateBetweenFirstAndFifth(currentDate);
    },
    tooltipContentForLatestMonth: function() {
      return locales.en.reports.availableNextMonth + getAbbreviatedMonth(new Date());
    }
  },
}
</script>
<style lang="scss">
/* width */
.c-dropdown__container::-webkit-scrollbar {
  display: block;
  width: 8px;
  height: 8px;
}

/* Track */
.c-dropdown__container::-webkit-scrollbar-track {
  background: $color_white;
  border-radius: 5px;
}

/* Handle */
.c-dropdown__container::-webkit-scrollbar-thumb {
  background: $neutral_300;
  border-radius: 5px;
}

/* Handle on hover */
.c-dropdown__container::-webkit-scrollbar-thumb:hover {
  background: $neutral_400;
  border-radius: 5px;
}

.report-filter-header-container {
  margin-bottom: 14px;

  & .report-filter-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & .filter-row {
      display: flex;
      flex-direction: row;
    }

    & .filter-row-button {
      display: flex;
      flex-direction: row;
      min-width: 250px;
    }

    & .filter {
      z-index: 99;
      margin-right: 12px;
      display: flex;

      & .c-dropdown__container {
        max-height: 300px;
        overflow: scroll;
      }

      & .c-dropdown__item--disabled {
        /* color/neutral/800 */
        color: #21252A;
        opacity: 0.5;
      }
    }

    & .filter:last-child {
      margin-right: 20px;
    }

    & .filter-hide {
      display: none;
    }

    & .filter-apply-button {
      height: 30px;
      line-height: 18px;
      margin-right: 20px;
      border-radius: 6px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      padding: 0;
      visibility: visible;
    }

    & .filter-clear-button {
      height: 32px;
      line-height: 18px;
      margin-right: 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: $neutral_800;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      visibility: visible;

      & .filter-icon {
        margin-right: 10px;
      }
    }

    & .hidden {
      visibility: hidden;
    }

    & .disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    & .filter-button {
      background: #FFFFFF;
      border: 1px solid #D1D5DB;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
      border-radius: 6px;
      height: 32px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      padding: 0;

      & .c-button__label {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding-left: 12px;
        padding-right: 12px;
      }

      & .filter-name-value-container {
        display: flex;
      }

      & .filter-name {
        color: $neutral_800;
        font-weight: $weight_normal;
      }

      & .filter-value {
        margin-left: 5px;
        color: $primary_600;
        font-weight: 500;

        & .filter-value-container {
          display: flex;
          flex-direction: row;
        }

        .filter-value-item ~ .filter-value-item::before {
          content: ", ";
        }

        .filter-value-item:last-child {
          margin-right: 5px;
        }
      }

      & .filter-icon {
        margin-left: 8px;
        transform: rotate(0deg);
        transition: transform 1s linear;
      }

      & .filter-icon .open {
        transform: rotate(180deg);
        transition: transform 1s linear;
      }
    }

    & .filter-date-item-container {
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: $neutral_800;
      display: flex;
      flex-direction: row;

      & .filter-date-item-check {
        margin: 0 10px;
        visibility: hidden;
      }

      & .filter-date-item-active {
        visibility: visible;
      }
    }
  }
}
</style>