<template>
  <div class="main-container">
    <SidebarNav @update:sidebarNavCollapsed="sidebarNavCollapsed = $event" />
    <SearchBar :sidebar-nav-collapsed="sidebarNavCollapsed" />
    <div
      class="exports-container"
      :class="{['exports-container-sidebar-collapse']: sidebarNavCollapsed}"
    >
      <div class="exports-sub-container">
        <div class="table-header">
          {{ $t('exports.title') }}
        </div>
        <div class="table-sub-header">
          {{ $t('exports.sub_title') }}
        </div>
        <div class="count-container">
          <div v-if="count!==0" class="showing-count" :class="{'hide-loader': loading.all || exports.length===0}">
            {{ $t('exports.showing') }} <b class="pagination">{{ countLabel }}</b>
          </div>
          <div v-else class="showing-count" :class="{'hide-loader': loading.all}">
            {{ $t('exports.noExportsFound') }}
          </div>
          <div class="loader" :class="{'hide-loader': !loading.all}"></div>
        </div>
        <TableView
          entity="Exports"
          :data="exports"
          :loading="loading.all"
          :all-items-loaded="allItemsLoaded"
          :infinite-scroll="true"
          :fixed-first-column="false"
          class="exports-table"
          :columns="{
            'requested': 'exports.columns.requested',
            'expires': 'exports.columns.expires',
            'status': 'exports.columns.status',
            'requested_from': 'exports.columns.requested_from',
            'download_link': 'exports.columns.download_link',
          }"
          :column-right-align="[]"
        >
          <template #requested="prop">
            <div class="export-requested-column">
              {{ formatter({type: 'zonedDateWithTime', data: prop.item.created_at}) }}
            </div>
          </template>
          <template #expires="prop">
            <ToolTip
              placement="top"
              :content="formatter({type: 'timeWithZone', data: prop.item.expires_at})"
              :enabled="prop.item.expires_at"
            >
              <div class="expires_at" v-if="prop.item.expires_at">
                {{ formatter({type: 'zonedDate', data: prop.item.expires_at}) }}
              </div>
              <div class="empty-placeholder" v-else>
                —
              </div>
            </ToolTip>
          </template>
          <template #status="prop">
            <div class="export-column">
              <div class="status-tag-container">
                <div
                  class="status"
                  :class="{
                    'success-status': prop.item.status === 'COMPLETED',
                    'failure-status': prop.item.status === 'FAILED',
                    'progress-status': prop.item.status === 'REQUESTED' || prop.item.status === 'INITIATED'
                  }"
                >
                  {{ $t('exports.status.' + prop.item.status) }}
                </div>
              </div>
            </div>
          </template>
          <template #requested_from="prop">
            {{ prop.item.requested_from }}
          </template>
          <template #download_link="prop">
            <div class="download-column" :class="prop.item.status === 'COMPLETED' ? 'completed':'not-completed'">
              <c-button
                variant="primary"
                :disabled="prop.item.status !== 'COMPLETED' || isAdminMode"
                :loading="loading.download === prop.item.id"
                @click="downloadExports(prop.item.id)"
              >
                <ToolTip
                  :enabled="prop.item.status !== 'COMPLETED' && !isAdminMode"
                  placement="top"
                  :content="tooltipContent(prop.item.status)"
                >
                  <svg
                    width="12" height="12" viewBox="0 0 12 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                    class="download-icon"
                  >
                    <path d="M1.99999 11.3327C1.63332 11.3327 1.31955 11.2022 1.05866 10.9413C0.797323 10.68 0.666656 10.366 0.666656 9.99935V7.99935H1.99999V9.99935H9.99999V7.99935H11.3333V9.99935C11.3333 10.366 11.2029 10.68 10.942 10.9413C10.6807 11.2022 10.3667 11.3327 9.99999 11.3327H1.99999ZM5.99999 8.66602L2.66666 5.33268L3.59999 4.36602L5.33332 6.09935V0.666016H6.66666V6.09935L8.39999 4.36602L9.33332 5.33268L5.99999 8.66602Z" fill="currentColor" />
                  </svg>
                  {{ $t('exports.download') }}
                </ToolTip>
              </c-button>
            </div>
          </template>
        </TableView>
        <PortalFooter />
      </div>
    </div>
  </div>
</template>

<script>

import SidebarNav from "@/components/SidebarNav";
import {mapActions, mapGetters} from "vuex";
import Formatter from "@/app/utils/common/functions/formatter";
import exports from "@/api/exports";
import TableView from "@/components/common/TableView";
import {Sort, SortBy} from "@/api/paymentApi";
import {portalCheckMixin} from "@/mixin/portalPageCheck";
import SearchBar from "@/components/SearchBar";
import PortalFooter from "@/components/portal/PortalFooter.vue";
import {PendoPrefixes} from "@/app/utils/common/constants";
import {getDateTimeFromObject} from "@/app/utils/common/functions/dateUtils";
import ToolTip from "@/components/common/ToolTip.vue";

export default {
  name: 'ExportView',
  components: {ToolTip, SearchBar, TableView, SidebarNav, PortalFooter},
  mixins: [portalCheckMixin],
  data: () => {
    return {
      exports: [],
      offsetId: null,
      listElm: null,
      pageSize: 15,
      dateFilter: {
        startDate: undefined,
        endDate: undefined,
      },
      allItemsLoaded: false,
      loading: {
        all: false,
        download: null,
      },
      sidebarNavCollapsed: false,
      count: 0,
    }
  },
  computed: {
    ...mapGetters('app', ['getMerchantId', 'isAdminMode']),
  countLabel() {
    return this.exports.length + " / " + this.count;
  }
  },
  mounted() {
    this.portalChecks().then(() => {
      this.initialize();
    });
  },
  methods: {
    ...mapActions('app', ['updateAppState']),
    formatter: Formatter.format,
    initialize() {
      let date = getDateTimeFromObject().toUTC();
      this.dateFilter.startDate = date.minus({days: 7}).toJSDate();
      this.dateFilter.endDate = date.toJSDate();

      this.listElm = document.querySelector('#infinite-list');
      this.listElm?.addEventListener('scroll', () => {
        if ((this.listElm.scrollTop + this.listElm.clientHeight + 1 >= this.listElm.scrollHeight) && this.listElm.scrollTop !== 0 && !this.loading.all) {
          this.loadMore();
        }
      });

      this.loadMore();
    },
    async loadMore() {
      if (this.allItemsLoaded === true) return;

      this.loading.all = true;

      let response;
      try {
        response = await exports.listMerchantExports(
            this.getMerchantId, this.offsetId, this.pageSize, SortBy.ID, Sort.DESC,
            this.dateFilter.startDate, this.dateFilter.endDate);
      } catch (e) {
        this.$danger("Could not load exports!")
        this.loading.all = false;
        return;
      }
      this.offsetId = response.cursor;
      this.count = response.count;
      this.exports = this.exports.concat(response.exports);

      if (response.exports.length < this.pageSize) {
        this.allItemsLoaded = true;
      }
      this.loading.all = false;
    },
    downloadExports(exportId) {
      this.loading.download = exportId;
      exports.downloadExport(
          this.getMerchantId, exportId)
      .then(response =>{
        this.loading.download = null;
        window.open(response.url);
      }).catch(err => {
        this.loading.download = null;
        this.$danger(err.source?.title)
      });
    },
    tooltipContent(status) {
      return status === 'REQUESTED' || status === 'INITIATED'
          ? 'Export is in progress, try after sometime.'
          : 'Retry export';
    },
    getElementId(id) {
      return PendoPrefixes.CBpay + this.$route.name + '-' + id;
    }
  },
}

</script>

<style lang="scss">

.main-container {
  background: $neutral_50;
  height: 100%;
  overflow: scroll;
}

.exports-container {
  left: 256px;
  top: 0;
  position: relative;
  width: calc(100% - 256px);
  padding: 20px 30px 30px 30px;
  transition: all 0.2s ease-in;
  justify-content: center;
  display: flex;

  & .exports-sub-container{
    max-width: 1360px;
    width: 100%;
  }

  & .table-header {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: $neutral_1000;
    text-align: left;
    padding: 20px 0 10px 0;
  }

  & .table-sub-header {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $neutral_600;
    text-align: left;
    padding: 0 0 30px 0;
  }

  &.exports-container-sidebar-collapse {
    left: 86px;
    width: calc(100% - 86px);

  }

  & .action-header {
    background-color: $color_white;
    width: 100%;
    border-radius: 6px 6px 0px 0px;
    height: 80px;
    justify-content: left;
    display: flex;
    padding: 0 20px;
    margin-top: 20px;
    flex-direction: row;

    & .export-count {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $neutral_1000;
      align-self: center;
    }
  }

  .count-container {
    background-color: $color_white;
    width: 100%;
    height: 50px;
    border: 1px solid $neutral_100;
    border-radius: 6px 6px 0px 0px;
    display: flex;
    justify-content: left;
    padding: 30px 20px 30px 20px;
    align-items: center;

    & .showing-count {
      display: inline-block;
      justify-content: left;

      & .pagination {
        font-weight: $weight_bold;
      }
    }

    & .loader {
      display: inline-block;
      width: 30px;
      height: 30px;
      border: 3px solid #D4D4E8;
      border-radius: 50%;
      border-top-color: #fff;
      animation: spin 1s ease-in-out infinite;
      justify-content: center;
    }
    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }

    & .hide-loader {
      display: none;
      visibility: hidden;
    }
  }

  & .export-requested-column {
    display: flex;
    font-size: 14px;
    font-weight: $weight_normal;
    line-height: 20px;
    color: $neutral_1000;
    padding: 8px 8px 8px 0;
  }

  & .download-column {
    & .c-button {
      width: fit-content;
      height: 32px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      flex-direction: row;
      padding: 0;
      top: 5px;
      color: #117DB6;
      background: $base_white;
      border: none;

      & img{
        margin-right: 5px;
      }
    }
    & .c-button__label {
      padding: 0;
    }
    & .c-button:hover {
      box-shadow: none;
    }
  }

  & .completed {
    opacity: 1;
  }

  & .not-completed {
    opacity: 0.5;
  }

  & .type-column {
    display: flex;

    & .type {
      display: flex;
      flex-direction: row;
      align-items: start;
      font-size: 14px;
      font-weight: $weight-normal;
      line-height: 20px;
      color: $neutral_1000;
      width: 100%;
      justify-content: start;

      & .empty-placeholder {
        color: $neutral_50;
      }

      & .amount-container{
        justify-content: right;
        display: flex;
        flex-direction: row;
        padding-right: 20%;
        padding-left: 20px;
        width: 100%;

        & .amount {
          margin-right: 5px;
        }

        & .currency_code {
          color: $neutral_400;
          font-size: 14px;
        }
      }
    }
  }

  & .export-column {
    display: flex;
    flex-direction: row;

    & .status-tag-container {
      display: flex;
      text-align: left;
      min-width: 70px;
      flex-direction: row;
      justify-content: space-between;
    }

    & .status {
      font-size: 12px;
      padding: 2px 10px;
      border-radius: 10px;
      height: fit-content;
    }

    & .success-status {
      background-color: $success_100;
      color: $success_800;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }

    & .failure-status {
      background-color: $danger_50;
      color: $danger_800;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }

    & .progress-status {
      background-color: $primary_100;
      color: $primary_800;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }
  }

}

</style>